import { Banner, Button, IcFluentChevronDown24Regular, IcFluentChevronUp24Regular } from '@flipgrid/flipkit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CSVErrorBannerStyles from '~/styles/components/Utility/components/CSVErrorBanner.css';

type Props = {
  mainError: string;
  errorDetails?: string;
};

export const links = () => [{ rel: 'stylesheet', href: CSVErrorBannerStyles }];

const CSVErrorBanner = ({ errorDetails, mainError }: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Banner theme="redSecondaryWithBg" className="CSVErrorBanner">
      <div className="CSVErrorBanner__mainError">
        {mainError}
        {errorDetails &&
        <Button
          theme="clear"
          className="CSVErrorBanner__button"
          data-testid="csvErrorBanner__button__caret"
          aria-label={t('csvErrorBanner.expand')}
          onClick={() => setExpanded((e) => !e)}
          icon={
          expanded ?
          <IcFluentChevronUp24Regular width={12} height={12} /> :

          <IcFluentChevronDown24Regular width={12} height={12} />} />}




      </div>
      {expanded && <p className="CSVErrorBanner__expanded fk-m0 mt1">{errorDetails}</p>}
    </Banner>);

};

export default CSVErrorBanner;