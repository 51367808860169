import { useFetcher, useParams } from '@remix-run/react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import AddEmailsModal, { links as addEmailsModalStyles } from './GroupTypes/AddEmailsModal';
import AddUsernamesModal, { links as addUsernamesModalStyles } from './GroupTypes/AddUsernamesModal';
import GoogleClassroomModal, {
  links as googleClassroomModalStyles,
} from './GroupTypes/GoogleClassroom/GoogleClassroomModal';
import UsernameRestrictionModal from './GroupTypes/UsernameRestrictionModal';
import ShareGroupModal, { links as shareGroupModalStyles } from './ShareGroupModal';
import routes from '~/constants/routes';
import GlobalContext from '~/contexts/globalContext';
import ShareGroupModalContext from '~/contexts/shareGroupModalContext';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { FetcherWithComponents } from '@remix-run/react';
import type { FormEvent } from 'react';
import type { ApiResponse, DisplayGroup, Group, Member, RouteTyping } from 'types';

export const links = () => [
  ...addEmailsModalStyles(),
  ...shareGroupModalStyles(),
  ...googleClassroomModalStyles(),
  ...addUsernamesModalStyles(),
];

type Props = {
  group: DisplayGroup | Group;
  onRequestClose: OnRequestCloseType;
  updateAccessControl?: (e: FormEvent<HTMLFormElement>) => void;
  updateDomainsAndEmails?: (formData: FormData) => void;
};

const ShareGroupModalWrapper = ({ group, onRequestClose, updateDomainsAndEmails, updateAccessControl }: Props) => {
  const params = useParams<{ vanity?: string }>();
  const memberFetcher = useFetcher() as FetcherWithComponents<RouteTyping<'GroupsMembersTab'>>;
  const { announceLiveMessage } = useContext(GlobalContext);
  const [showShareGroupModal, setShowShareGroupModal] = useState(true);
  const [showAddUsernamesModal, setShowAddUsernamesModal] = useState(false);
  const [memberResponse, setMemberResponse] = useState<ApiResponse<Member[]>>();
  const [showUsernameRestrictionPrompt, setShowUsernameRestrictionPrompt] = useState(false);
  const [showAddEmailsModal, setShowAddEmailsModal] = useState(false);
  const [showGoogleClassroomModal, setShowGoogleClassroomModal] = useState(false);
  const [memberList, setMemberList] = useState<Member[]>([]);
  const isLoggedOutGroup = 'resource_type' in group;
  const isMemberRequestingToJoin = isLoggedOutGroup || group.membership?.role === 'requested' || !group.vanity_token;
  const [shouldPreloadMembers, setShouldPreloadMembers] = useState(!isMemberRequestingToJoin);
  const hasEmailDomains = group.email_domains && group.email_domains.length > 0;
  const showUsernameButton =
    (group.access_control !== 'student' && memberList.length >= 1 && !hasEmailDomains) ||
    group.access_control === 'student';

  // context state controls
  const [accessControl, setAccessControl] = useState(group.access_control);
  const [vanityToken, setVanityToken] = useState(
    isLoggedOutGroup ? (group.resource_type !== 'grid' ? undefined : params.vanity) : group.vanity_token,
  );

  const contextValues = useMemo(
    () => ({
      accessControl,
      setAccessControl,
      setVanityToken,
      vanityToken,
    }),
    [accessControl, vanityToken],
  );

  const loadMembers = useCallback(
    (page: number) => {
      const isStudentGroup = group.access_control === 'student';
      const studentQueryString = new URLSearchParams([
        ['page', page.toString()],
        ['order_by', 'created_at'],
        ['roles', 'username'],
        ['manage', 'true'],
      ]);
      const membersQueryString = new URLSearchParams([
        ['page', '1'],
        ['per_page', '5'],
      ]);

      const qs = isStudentGroup ? studentQueryString : membersQueryString;
      memberFetcher.load(`${routes.GROUPS_ID_MEMBERS_FUNC(group.id)}?${qs}`);
    },
    [group.access_control, group.id, memberFetcher],
  );

  const announceIfMessage = (message?: string) => {
    if (message && typeof message === 'string') announceLiveMessage(message);
  };

  useEffect(() => {
    if (shouldPreloadMembers) {
      loadMembers(1);
      setShouldPreloadMembers(false);
    }
  }, [loadMembers, memberFetcher.state, shouldPreloadMembers]);

  useEffect(() => {
    if (memberFetcher.data && memberFetcher.type === 'done') {
      const updatedMemberList = memberFetcher.data.members?.data ?? [];
      setMemberList(prev => [...prev, ...updatedMemberList]);
      setMemberResponse(memberFetcher.data.members);
    }
  }, [memberFetcher]);

  return (
    <>
      <ShareGroupModalContext.Provider value={contextValues}>
        {showShareGroupModal && (
          <ShareGroupModal
            group={group}
            memberList={memberList}
            onLoadAddEmailsModal={() => {
              setShowAddEmailsModal(true);
              setShowShareGroupModal(false);
            }}
            onLoadGoogleClassroomModal={() => {
              setShowGoogleClassroomModal(true);
              setShowShareGroupModal(false);
            }}
            onLoadUsernameRestrictionPromptModal={() => {
              setShowUsernameRestrictionPrompt(true);
              setShowShareGroupModal(false);
            }}
            onLoadUsernamesModal={() => {
              setShowAddUsernamesModal(true);
              setShowShareGroupModal(false);
            }}
            onRequestClose={onRequestClose}
            showUsernameButton={showUsernameButton}
            updateAccessControl={updateAccessControl}
            updateDomainsAndEmails={updateDomainsAndEmails}
          />
        )}

        {showUsernameRestrictionPrompt && !isLoggedOutGroup && (
          <UsernameRestrictionModal
            groupId={group.id}
            onRequestClose={() => {
              setShowShareGroupModal(true);
              setShowUsernameRestrictionPrompt(false);
            }}
            onContinue={() => {
              setShowUsernameRestrictionPrompt(false);
              setShowAddUsernamesModal(true);
            }}
          />
        )}
        {showGoogleClassroomModal && !isLoggedOutGroup && (
          <GoogleClassroomModal
            group={group}
            onRequestClose={() => {
              setShowShareGroupModal(true);
              setShowGoogleClassroomModal(false);
            }}
            onSuccess={e => {
              setShowShareGroupModal(true);
              setShowGoogleClassroomModal(false);
              announceIfMessage(e);
            }}
          />
        )}
        {showAddEmailsModal && updateDomainsAndEmails && !isLoggedOutGroup && (
          <AddEmailsModal
            group={group}
            onRequestClose={() => {
              setShowShareGroupModal(true);
              setShowAddEmailsModal(false);
            }}
            onCompletion={e => {
              setShowShareGroupModal(true);
              setShowAddEmailsModal(false);
              announceIfMessage(e);
            }}
          />
        )}
        {showAddUsernamesModal && !isLoggedOutGroup && (
          <AddUsernamesModal
            group={group}
            onRequestClose={() => {
              setShowShareGroupModal(true);
              setShowAddUsernamesModal(false);
            }}
            memberList={memberList}
            memberPagination={memberResponse?.metadata.pagination}
            loadMembers={loadMembers}
            memberFetcherState={memberFetcher.state}
            onSuccess={e => {
              setShowShareGroupModal(true);
              setShowAddUsernamesModal(false);
              announceIfMessage(e);
            }}
            setMemberList={setMemberList}
          />
        )}
      </ShareGroupModalContext.Provider>
    </>
  );
};

export default ShareGroupModalWrapper;
