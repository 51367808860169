import { Button } from '@flipgrid/flipkit';
import { useFetcher, useMatches, useParams } from '@remix-run/react';
import { useCallback, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import CopyShareLink, { links as copyShareLinkStyles } from './CopyShareLink';
import DeactivateShareLinkModal from './DeactivateShareLinkModal';
import Radio from '../FkWrappers/Radio';
import GlobalContext from '~/contexts/globalContext';
import shareGroupModalContext from '~/contexts/shareGroupModalContext';
import { isFlagPresent, removeHttpsFromUrl } from '~/helper/helper';
import { isMemberGroupLead } from '~/helper/userRoles';
import linkSettingsStyles from '~/styles/components/Share/LinkSettings.css';

import type { FormEvent } from 'react';
import type { DisplayGroup, Group } from 'types';

export const links = () => [...copyShareLinkStyles(), { rel: 'stylesheet', href: linkSettingsStyles }];

type Props = {
  group: Group | DisplayGroup;
  isEditing?: boolean;
  updateAccessControl?: (e: FormEvent<HTMLFormElement>) => void;
};

const LinkSettings = ({ group, isEditing = false, updateAccessControl }: Props) => {
  const { accessControl, setAccessControl } = useContext(shareGroupModalContext);
  const { announceLiveMessage } = useContext(GlobalContext);
  const { t } = useTranslation();
  const params = useParams();
  const [routeData] = useMatches();
  const fetcher = useFetcher();
  const [editMode, setEditMode] = useState(isEditing);
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const isLoggedOutGroup = ('resource_type' in group);
  const isGroupLead = !isLoggedOutGroup && isMemberGroupLead(group);
  const isStudentGroup = group.access_control === 'student';
  const { featureFlags } = useContext(GlobalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  useEffect(() => {
    const resetFetcher = () => {
      fetcher.data = undefined;
    };

    const hasResponse = fetcher.data;
    if (fetcher.type === 'done' && hasResponse) {
      resetFetcher();
      announceLiveMessage(t('linkSettings.shareLinkUpdated'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher, t]); // announceLiveMessage as a dependency causes an infinite loop

  const Label = useCallback(() => {
    const shouldShowEditBtn = !editMode && isGroupLead;
    return (
      <div className="linkSettings__inviteEdit">
        <span id="linkSettings-whoCanJoin">{t('shared.inviteLink')}</span>
        {isDeprecated ?
        null :
        shouldShowEditBtn &&
        <Button
          aria-label={t('linkSettings.editLabel')}
          variant="text"
          onClick={() => setEditMode((prev) => !prev)}
          data-testid="linkSettings__button__edit"
          className="linkSettings__editBtn fk-m0">

                {t('linkSettings.edit')}
              </Button>}

      </div>);

  }, [editMode, isDeprecated, isGroupLead, t]);

  return (
    <div className="linkSettings">
      <CopyShareLink
        groupId={group.id}
        isEditMode={editMode}
        isStudentGroup={isStudentGroup}
        label={(<Label /> as TSFix)}
        leftButtonTheme="secondary"
        onTokenUpdate={() => setEditMode(false)}
        rootPath={`${removeHttpsFromUrl(routeData.data.env.CLIENT_SHARE_URL)}/`}
        shouldShowRefreshBtn={isGroupLead}
        vanityToken={isLoggedOutGroup ? (params.vanity as string) : group.vanity_token}
        includeLeftButton />

      {isGroupLead && !isStudentGroup &&
      <fetcher.Form method="post" onChange={updateAccessControl}>
          <div
          className="linkSettings__radioList fk-radio__verticalContainer"
          role="radiogroup"
          aria-labelledby="linkSettings-whoCanJoin">

            <Radio
            label={t('linkSettings.anyoneWithLink')}
            name="access_control"
            onChange={() => setAccessControl('pln')}
            selected={accessControl === 'pln'}
            size="small"
            value="pln" />

            {isDeprecated ? null :
          <Radio
            label={t('common.onlyPeopleApproved')}
            name="access_control"
            onChange={() => setAccessControl('domain')}
            selected={accessControl !== 'pln'}
            size="small"
            value="domain" />}


            <input type="hidden" name="_action" value="updateGroup" />
            <input type="hidden" name="id" value={group.id} />
          </div>
        </fetcher.Form>}

      {deactivateModalOpen &&
      <DeactivateShareLinkModal gridID={group.id} onRequestClose={() => setDeactivateModalOpen(false)} />}

    </div>);

};

export default LinkSettings;