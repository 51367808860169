import { Button, ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher, useMatches } from '@remix-run/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import handleIds from '~/constants/handleIds';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { Group } from 'types/Group';

type Props = {
  group: Group;
  onRequestClose: OnRequestCloseType;
};

const DisconnectCanvasModal = ({ group, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1];
  const isGroupsPage = lastMatch.handle?.id === handleIds.Groups;

  useEffect(() => {
    if (fetcher.type === 'done') {
      onRequestClose(t('disconnectCanvasModal.successfullyDisconnected'));
    }
  }, [onRequestClose, t, fetcher]);

  return (
    <Modal onClose={onRequestClose}>
      <fetcher.Form method="post" action={resourceRoutes.disconnectCanvas}>
        <h1 className="fk-modalHeader">{t('disconnectCanvasModal.disconnectThisGroup')}</h1>
        <p className="fk-modalBody">{t('disconnectCanvasModal.noLongerAccess')}</p>
        <Modal.Actions className="mt2">
          <Button theme="secondary" onClick={onRequestClose} data-testid="disconnectCanvasModal__button__cancel">
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            name="_action"
            value="disconnectCanvas"
            theme="danger"
            loading={fetcher.state !== 'idle'}
            data-testid="disconnectCanvasModal__button__leave"
          >
            {t('disconnectCanvasModal.disconnectGroup')}
          </ButtonSubmit>
          <input type="hidden" name="groupId" value={group.id} />
          {isGroupsPage && <input type="hidden" name="onSuccessRedirect" value={routes.HOME} />}
        </Modal.Actions>
      </fetcher.Form>
    </Modal>
  );
};

export default DisconnectCanvasModal;
