import { Loader, Option, Select, useIntersectionObserver } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { isEmpty, uniqBy } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import GoogleRosterSessionStatus, { links as googleRosterSessionStatusStyles } from './GoogleRosterSessionStatus';
import resourceRoutes from '~/constants/resourceRoutes';
import { getFullDate } from '~/helper/dateHelpers';
import { isMemberGroupOwner } from '~/helper/userRoles';
import useGetUser from '~/hooks/useGetUser';

import type { GoogleClassroom, GoogleClassroomApiResponse, GoogleToken, Group } from 'types';

export const links = () => [...googleRosterSessionStatusStyles()];

type Props = {
  group: Group;
  onChange?: (course: GoogleClassroom | undefined) => void;
};

const GoogleRoster = ({ group, onChange }: Props) => {
  const { t } = useTranslation();
  const coursesRef = useRef<HTMLDivElement>(null);
  const isCoursesEndVisible = useIntersectionObserver(coursesRef);
  const fetcher = useFetcher<GoogleClassroomApiResponse>();
  const user = useGetUser();
  const [courses, setCourses] = useState<GoogleClassroom[]>([]);
  const [googleToken, setGoogleToken] = useState<GoogleToken>();
  const [lastSyncedAt, setLastSyncedAt] = useState(
    group.provider_grid_connection?.last_synced_at
      ? getFullDate(group.provider_grid_connection?.last_synced_at)
      : undefined,
  );
  const [syncError, setSyncError] = useState({
    hasError: false,
    message: '',
  });

  const [hasInitializedClassrooms, setHasInitializedClassrooms] = useState(false);
  const isGroupOwner = isMemberGroupOwner(group);
  const hasActiveSession = !!googleToken;
  const currentUserIsRosterManager = user.id === group.provider_grid_connection?.user_id;

  const isLoadingCourses = fetcher.type !== 'done';

  const loadMoreCourses = useCallback(() => {
    const qs = new URLSearchParams([]);
    if (fetcher.data?.metadata.next_page_token) {
      qs.append('next_page_token', fetcher.data.metadata.next_page_token);
    }
    fetcher.load(`${resourceRoutes.googleRoster}?${qs}`);
  }, [fetcher]);

  useEffect(() => {
    if (hasActiveSession && !hasInitializedClassrooms && fetcher.state === 'idle') {
      loadMoreCourses();
      setHasInitializedClassrooms(true);
    }
  }, [fetcher.state, googleToken, hasActiveSession, hasInitializedClassrooms, loadMoreCourses]);

  useEffect(() => {
    const hasClassroomResponse = !isEmpty(fetcher.data?.data);
    if (hasClassroomResponse) {
      setCourses(prev => {
        // needing uniqBy here is hack fix due to a Remix issue re-initializing our modals. When Remix fixes this uniqBy should be removed
        const uniqueCourses = uniqBy([...prev, ...(fetcher.data?.data ? fetcher.data.data : [])], 'uid');
        return uniqueCourses;
      });
    }
  }, [fetcher.data]);

  useEffect(() => {
    const hasNextPage = !!fetcher.data?.metadata.next_page_token;
    if (!isLoadingCourses && hasNextPage && isCoursesEndVisible) {
      loadMoreCourses();
    }
  }, [fetcher.data?.metadata.next_page_token, isLoadingCourses, isCoursesEndVisible, loadMoreCourses]);

  const onCourseSelect = (selectedValue: number | 'empty') => {
    if (!courses) return;
    if (selectedValue === 'empty' && onChange) onChange(undefined);
    const course = courses.find(item => item.uid === selectedValue);
    if (course && onChange) {
      onChange(course);
    }
  };

  return (
    <>
      <GoogleRosterSessionStatus
        googleToken={googleToken}
        group={group}
        onLogout={() => setHasInitializedClassrooms(false)}
        setGoogleToken={setGoogleToken}
        showUI={isGroupOwner}
        type="Group"
        onSyncError={err => {
          const errMessage = err?.body ? err?.body : t('googleRoster.genericSyncFailure');
          setSyncError({ hasError: true, message: errMessage });
        }}
        onSyncSuccess={() => setLastSyncedAt(getFullDate(new Date().toString()))}
        onSyncRequest={() => setSyncError({ hasError: false, message: '' })}
      />
      {syncError.hasError && <p className="color__error">{syncError.message}</p>}
      <>
        {hasActiveSession &&
          (!group.provider_grid_connection?.resource_id ||
            (group.provider_grid_connection?.resource_id && currentUserIsRosterManager)) &&
          (courses && courses.length > 0 ? (
            <Select
              wrapperClassName="mt05"
              label={t('googleRoster.class')}
              labelClassName="googleRoster__classSelectLabel"
              helpTextTop={lastSyncedAt && t('googleRoster.lastUpdated', { date: lastSyncedAt })}
              onChange={onCourseSelect}
              defaultValue={group.provider_grid_connection?.resource_id}
            >
              {!group.provider_grid_connection?.resource_id && (
                <Option value="empty">{t('googleRoster.noClassSelected')}</Option>
              )}
              {courses?.map(course => (
                <Option key={course.uid} value={course.uid}>
                  {course.name}
                </Option>
              ))}
              {isLoadingCourses && <Loader container />}
              <div aria-hidden="true" className="hidden" ref={coursesRef} />
            </Select>
          ) : (
            <div className="mt05">
              {isLoadingCourses ? <Loader container /> : <p>{t('googleRoster.noClassroomsFound')}</p>}
            </div>
          ))}
      </>
      {group.provider_grid_connection?.resource_id && !currentUserIsRosterManager && (
        <p className="m0 fk-helpText">
          <Trans i18nKey="googleRoster.mostRecentClassImport">
            Most recent class imported:
            <strong>{{ classroomName: group.provider_grid_connection?.resource_name }}</strong>, last updated on
            <strong>{{ date: getFullDate(group.provider_grid_connection?.last_synced_at ?? '') }}</strong>
          </Trans>
        </p>
      )}
    </>
  );
};

export default GoogleRoster;
