import { ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GoogleRoster, { links as googleRostersStyles } from './GoogleRoster';
import BackButtonHeader, { links as backButtonHeaderStyles } from '~/components/Utility/components/BackButtonHeader';
import resourceRoutes from '~/constants/resourceRoutes';
import shareGroupModalContext from '~/contexts/shareGroupModalContext';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { ApiResponse, GoogleClassroom, Group } from 'types';

export const links = () => [...backButtonHeaderStyles(), ...googleRostersStyles()];

type Props = {
  group: Group;
  onRequestClose: OnRequestCloseType;
  onSuccess: OnRequestCloseType;
};

const GoogleClassroomModal = ({ group, onRequestClose, onSuccess }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher<ApiResponse<Group>>();

  const [googleClassroom, setGoogleClassroom] = useState<GoogleClassroom>();
  const { setAccessControl } = useContext(shareGroupModalContext);

  useEffect(() => {
    if (fetcher.type === 'done' && fetcher.data?.data.id) {
      onSuccess(t('googleClassroomModal.successfullyUpdatedGoogleClassroom'));
      setAccessControl('domain');
    }
  }, [fetcher, onSuccess, setAccessControl, t]);

  return (
    <Modal onClose={onRequestClose} animation={false}>
      <fetcher.Form action={`${resourceRoutes.googleRoster}?group_id=${group.id}`} method="post">
        <BackButtonHeader
          onRequestClose={onRequestClose}
          titleText={t('shared.googleClassroom')}
          data-testid="backButtonHeader__button__otherInviteMethods__googleClassroomModal"
        />

        <div className="fk-modalBody">
          <GoogleRoster group={group} onChange={selectedClass => setGoogleClassroom(selectedClass)} />
        </div>
        <Modal.Actions className="mt2">
          <ButtonSubmit
            data-testid="googleClassroomModal__buttonSubmit__done"
            variant="block"
            name="_action"
            value="setGridToken"
            disabled={!googleClassroom}
            loading={fetcher.state !== 'idle'}
          >
            {t('common.done')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="grid_id" value={group.id} />
        <input type="hidden" name="access_control" value={group.access_control} />
        <input
          type="hidden"
          name="provider_grid_connection_attributes"
          value={
            googleClassroom
              ? JSON.stringify({
                  resource_id: googleClassroom.uid,
                  resource_name: googleClassroom.name,
                  resource_type: 'google',
                })
              : undefined
          }
        />
      </fetcher.Form>
    </Modal>
  );
};

export default GoogleClassroomModal;
