import { Button } from '@flipgrid/flipkit';
import Papa from 'papaparse';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { emailArrayBuilderWithErrors, validateEmail } from '~/helper/domainCsvHelper';

import type { ChangeEvent } from 'react';

type UploadResponse = {
  csvErrors: {
    mainErrors: string;
    errorDetails?: string;
    emailFormError: string | null;
    emailsAndDomains?: { value: string; email: boolean }[]; // invalid emails
  };
  emailsAndDomains?: { value: string; email: boolean }[]; // valid emails
};

type ParsedEmail = {
  email: string;
};

type Props = {
  onChange: (response: UploadResponse) => void;
};

const EmailCSVUpload = ({ onChange }: Props) => {
  const { t } = useTranslation();
  const csvFile = useRef<HTMLInputElement>(null);

  const parseUserCSV = (file: File): Promise<ParsedEmail[]> => {
    return new Promise(resolve => {
      Papa.parse(file, {
        header: true,
        dynamicTyping: false,
        skipEmptyLines: true,
        complete: results => {
          resolve(results.data as ParsedEmail[]);
        },
      });
    });
  };

  const createEmailErrorDetailsString = (invalidEmails: string[], duplicateEmails: string[], newEmails: string[]) => {
    if (invalidEmails.length > 0 && duplicateEmails.length > 0) {
      return t('emailCSVUpload.incorrectAndDuplicateEmails', {
        incorrectEmailCount: invalidEmails.length,
        listOfInvalidRecords: invalidEmails.map(email => newEmails.indexOf(email) + 2).join(', '),
        duplicateEmailCount: duplicateEmails.length,
        listOfDuplicateEmails: duplicateEmails.map(email => newEmails.indexOf(email) + 2).join(', '),
      });
    }
    if (invalidEmails.length > 0) {
      return t('emailCSVUpload.incorrectEmailsAllMembers', {
        count: invalidEmails.length,
        listOfInvalidRecords: invalidEmails.map(email => newEmails.indexOf(email) + 2).join(', '),
      });
    }

    return t('emailCSVUpload.duplicateEmailsAllMembers', {
      count: duplicateEmails.length,
      listOfDuplicateEmails: duplicateEmails.map(email => newEmails.indexOf(email) + 2).join(', '),
    });
  };

  const readCSVFile = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.target || !e.target?.files) return;

    const file = e.target.files[0];
    const array = await parseUserCSV(file);
    // if email is undefined it means there is not a column on the csv with the title email
    // so we can assume there are no valid entries
    if (!array.length || array[0].email === undefined) {
      onChange({
        csvErrors: {
          mainErrors: t('shared.missingCSVColumnError', { missingColumns: '"email"', count: 1 }),
          emailFormError: t('shared.errorOccurred'),
        },
      });
    } else {
      const newEmails = array.map(i => i.email);
      const [validEmails, invalidEmails, duplicateEmails] = emailArrayBuilderWithErrors(newEmails);
      const emailsWithErrors = invalidEmails.length + duplicateEmails.length;
      if (emailsWithErrors) {
        onChange({
          csvErrors: {
            mainErrors: t('emailCSVUpload.emailsIncorrectlyFormatted', {
              count: emailsWithErrors,
            }),
            errorDetails: createEmailErrorDetailsString(invalidEmails, duplicateEmails, newEmails),
            emailFormError: null,
            emailsAndDomains: validEmails.map(validEmail => ({ value: validEmail, email: validateEmail(validEmail) })),
          },
        });
      } else {
        onChange({
          csvErrors: { mainErrors: '', errorDetails: '', emailFormError: null },
          emailsAndDomains: validEmails.map(validEmail => ({ value: validEmail, email: validateEmail(validEmail) })),
        });
      }
    }
  };

  const fileUpload = () => {
    // In order to have a anchor as a file input, must click it via JS.
    if (csvFile.current?.click) csvFile.current.click();
  };

  return (
    <>
      <Button data-testid="emailCSVUpload__button__upload" onClick={fileUpload} variant="text">
        {t('shared.uploadCSV')}
      </Button>
      <input
        type="file"
        name="csv-import-button"
        accept=".csv"
        ref={csvFile}
        className="fk-hidden"
        onChange={e => {
          // this fixes an issue where if the user uploads the same file twice,
          // the input doesn't recognize the change and the onchange doesn't fire a second time
          e.persist();
          readCSVFile(e);
          e.target.value = '';
        }}
      />
    </>
  );
};

export default EmailCSVUpload;
