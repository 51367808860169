import { ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { has } from 'lodash-es';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import resourceRoutes from '~/constants/resourceRoutes';
import GlobalContext from '~/contexts/globalContext';

import type { OnRequestCloseType } from '@flipgrid/flipkit';

type Props = {
  gridID: number;
  onRequestClose: OnRequestCloseType;
};

const DeactivateShareLinkModal = ({ gridID, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const { announceLiveMessage } = useContext(GlobalContext);

  useEffect(() => {
    if (fetcher.type === 'done') {
      const hasTokenSuccessfullyReset = has(fetcher.data, 'data');
      let closeMessage = t('deactivateShareLinkModal.problemUpdatingShareLink');

      if (hasTokenSuccessfullyReset) closeMessage = t('shared.inviteLinkUpdated');

      announceLiveMessage(closeMessage);
      onRequestClose();
    }
  }, [announceLiveMessage, fetcher.data, fetcher.type, onRequestClose, t]);

  return (
    <Modal show onClose={onRequestClose}>
      <h1 className="fk-h2 fk-modalHeader">{t('deactivateShareLinkModal.deactivateCurrentLink')}</h1>
      <p className="fk-m0 fk-modalBody">{t('deactivateShareLinkModal.newShareLinkInstructions')}</p>
      <fetcher.Form method="post" action={resourceRoutes.updateState}>
        <Modal.Actions className="mt2">
          <ButtonSubmit
            name="_action"
            value="updateGroup"
            data-testid="deactivateShareLinkModal__button__refreshLink"
            loading={fetcher.type === 'actionSubmission' || fetcher.type === 'actionReload'}
          >
            {t('shared.refreshLink')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="grid_id" value={gridID} />
        <input type="hidden" name="vanity_token" value="" />
      </fetcher.Form>
    </Modal>
  );
};

export default DeactivateShareLinkModal;
