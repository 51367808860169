import { Banner, Button, ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { has } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import resourceRoutes from '~/constants/resourceRoutes';
import GlobalContext from '~/contexts/globalContext';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { ApiResponse, Group } from 'types';

type Props = {
  existingDomains: string[];
  groupId: number;
  onRequestClose: OnRequestCloseType;
  onSuccess: (removedDomain: string[]) => void;
  removedDomain: string;
};

const DomainDeleteModal = ({ existingDomains, groupId, onRequestClose, onSuccess, removedDomain }: Props) => {
  const { announceLiveMessage } = useContext(GlobalContext);
  const { t } = useTranslation();
  const fetcher = useFetcher<ApiResponse<Group>>();
  const [hasError, setHasError] = useState(false);

  const updatedDomains = existingDomains.filter(domain => domain !== removedDomain);

  useEffect(() => {
    if (fetcher.type === 'done') {
      const isSuccess =
        has(fetcher, 'data.data.email_domains') && !fetcher.data.data.email_domains?.includes(removedDomain);
      if (isSuccess) {
        announceLiveMessage(t('domainDeleteModal.successfullyRemovedDomain'));
        onSuccess(fetcher.data.data.email_domains ?? []);
        onRequestClose();
      } else setHasError(true);
    }
  }, [onSuccess, onRequestClose, removedDomain, announceLiveMessage, t, fetcher]);

  return (
    <Modal
      onClose={onRequestClose}
      topComponent={
        hasError && (
          <Banner className="mb2" theme="redSecondaryWithBg">
            {t('domainDeleteModal.problemRemovingDomain')}
          </Banner>
        )
      }
    >
      <fetcher.Form
        method="post"
        action={`${resourceRoutes.updateState}?manage=true`}
        onSubmit={() => setHasError(false)}
      >
        <h1 className="fk-modalHeader">{t('domainDeleteModal.removeDomain', { domain: removedDomain })}</h1>
        <p className="fk-modalBody">{t('domainDeleteModal.needToApproveAllStudents', { domain: removedDomain })}</p>
        <Modal.Actions className="mt2">
          <Button data-testid="domainDeleteModal__button__cancel" onClick={onRequestClose} theme="secondary">
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            data-testid="domainDeleteModal__button__remove"
            theme="danger"
            name="_action"
            value="updateGroup"
            loading={fetcher.state !== 'idle'}
          >
            {t('common.remove')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="id" value={groupId} />
        <input type="hidden" name="fieldsToParse" value={JSON.stringify(['email_domains'])} />
        <input type="hidden" name="email_domains" value={JSON.stringify(updatedDomains)} />
      </fetcher.Form>
    </Modal>
  );
};

export default DomainDeleteModal;
