import { Anchor, Button, IcFluentMail24Regular, IcFluentPerson24Regular, Modal } from '@flipgrid/flipkit';
import { useFetcher, useParams, useSearchParams } from '@remix-run/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LinkSettings, { links as linkSettingsStyles } from '../Share/LinkSettings';
import ShareQRCodeBtn, { links as shareQRCodeBtnStyles } from '../Share/ShareQRCodeBtn';
import InvitationMethod, { links as invitationMethodStyles } from '../Utility/components/InvitationMethod';
import externalLinks from '~/constants/externalLinks';
import routes from '~/constants/routes';
import GlobalContext from '~/contexts/globalContext';
import { isFlagPresent } from '~/helper/helper';
import { handleError } from '~/helper/imgOnError';
import { isMemberGroupLead } from '~/helper/userRoles';
import shareGroupModalStyles from '~/styles/components/Modals/ShareGroupModal.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { SyntheticEvent, FormEvent } from 'react';
import type { DisplayGroup, Group, Member, RouteTyping } from 'types';

export const links = () => [
...invitationMethodStyles(),
...linkSettingsStyles(),
...shareQRCodeBtnStyles(),
{ rel: 'stylesheet', href: shareGroupModalStyles }];


type Props = {
  group: Group | DisplayGroup;
  memberList: Member[];
  onLoadAddEmailsModal: () => void;
  onLoadGoogleClassroomModal: () => void;
  onLoadUsernameRestrictionPromptModal: () => void;
  onLoadUsernamesModal: () => void;
  onRequestClose: OnRequestCloseType;
  showUsernameButton?: boolean;
  updateAccessControl?: (e: FormEvent<HTMLFormElement>) => void;
  updateDomainsAndEmails?: (formData: FormData) => void;
};

const ShareGroupModal = ({
  group,
  memberList,
  onLoadAddEmailsModal,
  onLoadGoogleClassroomModal,
  onLoadUsernameRestrictionPromptModal,
  onLoadUsernamesModal,
  onRequestClose,
  showUsernameButton,
  updateDomainsAndEmails,
  updateAccessControl
}: Props) => {
  const { t } = useTranslation();
  const memberFetcher = useFetcher<RouteTyping<'GroupsMembersTab'>>();
  const params = useParams();
  const isLoggedOutGroup = ('resource_type' in group);
  const isGroupLead = !isLoggedOutGroup && isMemberGroupLead(group); // TODO: handle studentView
  const isStudentGroup = group.access_control === 'student';
  const shouldLoadMembers = !isLoggedOutGroup && isStudentGroup && isMemberGroupLead(group);
  const [shouldPreloadMembers, setShouldPreloadMembers] = useState(shouldLoadMembers);
  const functionalGroup = 'resource_type' in group && group?.resource_type !== 'grid' ? group.grid : group;
  const [searchParams, setSearchParams] = useSearchParams();
  const isNewGroup = searchParams.get('new');
  const { setQueuedTourNewGroup } = useContext(GlobalContext);
  const { featureFlags } = useContext(GlobalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  const loadMembers = useCallback(
    (page: number) => {
      const qs = new URLSearchParams([
      ['page', page.toString()],
      ['order_by', 'created_at'],
      ['roles', 'username'],
      ['manage', 'true']]
      );
      memberFetcher.load(`${routes.GROUPS_ID_MEMBERS_FUNC(group.id)}?${qs}`);
    },
    [group.id, memberFetcher]
  );

  const shouldShowMoreInviteOptions = () => {
    if (!updateDomainsAndEmails || !isGroupLead) return false;

    // access_control -> student
    const hasMembers = group.member_count > 1 || memberList.length > 1; // check initial group count, and for any newly added username members
    if (isStudentGroup && hasMembers) {
      return false;
    }

    return true;
  };

  const closeShareModal = () => {
    if (isNewGroup) {
      setQueuedTourNewGroup(true);
      searchParams.delete('new');
      setSearchParams(searchParams);
    }
    onRequestClose();
  };

  useEffect(() => {
    if (shouldPreloadMembers && memberFetcher.state === 'idle') {
      loadMembers(1);
      setShouldPreloadMembers(false);
    }
  }, [loadMembers, memberFetcher.state, shouldPreloadMembers]);

  return (
    <Modal className="shareGroupModal" closeButtonTheme="overlay" onClose={closeShareModal} animation={false}>
      <div className="shareGroupModal__headerWrapper">
        <h1 className="shareGroupModal__groupName">{group.name}</h1>
        <img
          alt={t('shared.groupCover')}
          className="shareGroupModal__image"
          src={`${functionalGroup.image_url}?size=medium`}
          onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)} />

      </div>
      <div className="shareGroupModal__bodyWrapper">
        <ShareQRCodeBtn
          btnClassName="shareGroupModal__qrCodeBtn"
          btnTheme="overlay"
          entity={{ type: 'Group', item: group }}
          token={isLoggedOutGroup ? (params.vanity as string) : group.vanity_token}
          btnSize="26" />

        <h1 className="fk-modalHeader text-center">
          {isLoggedOutGroup && group.resource_type === 'topic' ? t('shared.shareTopic') : t('shared.shareGroup')}
        </h1>
        <div className="fk-modalBody text-center shareGroupModal__inviteText">
          <p>{t('shareGroupModal.inviteAnyoneToJoinWithLink')}</p>
          <p>
            <b>{t('shared.googleEducatorHelpPrompt')}</b>
            <Anchor data-testid="shareGroupModal__googleEducatorHelpText" newTab to={externalLinks.EnableFlip}>
              {t('shared.googleEducatorHelpText')}
            </Anchor>
          </p>
        </div>

        {updateAccessControl && <LinkSettings group={group} updateAccessControl={updateAccessControl} />}

        {isGroupLead && isStudentGroup &&
        <InvitationMethod
          className="shareGroupModal__manageUsernames"
          mediaElement={<IcFluentPerson24Regular className="iconFill__fg-1" width="24" height="24" />}
          onClick={onLoadUsernamesModal}
          text={t('shareGroupModal.manageUsernames')}
          data-testid="invitationMethod__button__inviteOptions__manageUsernames" />}



        {isDeprecated ? null : shouldShowMoreInviteOptions() &&
        <>
            <hr className="shareGroupModal__hr" />
            <h2 className="fk-h4 mt0">{t('shareGroupModal.otherWays')}</h2>
            <InvitationMethod
            className="mb075"
            text={t('shared.googleClassroom')}
            onClick={onLoadGoogleClassroomModal}
            mediaElement={externalLinks.GoogleClassroomImage}
            data-testid="invitationMethod__button__inviteOptions__googleClassroom" />

            <InvitationMethod
            className="mb075"
            text={t('common.addEmailOrDomain')}
            onClick={onLoadAddEmailsModal}
            mediaElement={<IcFluentMail24Regular className="iconFill__fg-1" width="24" height="24" />}
            data-testid="invitationMethod__button__inviteOptions__addEmailOrDomain" />

            {showUsernameButton &&
          <Button
            className="mb075 shareGroupModal__addUsernames"
            theme="nav"
            variant="text"
            data-testid="shareGroupModal__button__addUsernames"
            onClick={onLoadUsernameRestrictionPromptModal}>

                {t('shareGroupModal.byUsername')}
              </Button>}

          </>}

      </div>
    </Modal>);

};

export default ShareGroupModal;