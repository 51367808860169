import { ModalConsumer } from '@flipgrid/flipkit';
import classNames from 'classnames';
import { useState } from 'react';

import ColeadInvites, { links as coleadInvitesStyles } from './ColeadInvites';
import resourceRoutes from '~/constants/resourceRoutes';
import useFetcherWatcher from '~/hooks/useFetcherWatcher';
import coleadInvitesWrapperStyles from '~/styles/components/Coleads/ColeadInvitesWrapper.css';

import type { LinksFunction } from '@remix-run/node';
import type { ColeadInvite } from 'types';

export const links: LinksFunction = () => {
  return [...coleadInvitesStyles(), { rel: 'stylesheet', href: coleadInvitesWrapperStyles }];
};

type Props = {
  className?: string;
  coleadInvites?: ColeadInvite[];
};

const ColeadInvitesWrapper = ({ className, coleadInvites }: Props) => {
  const [invites, setInvites] = useState(coleadInvites || []);

  const removeInvite = (id: number) => {
    setInvites((prevInvites) => {
      return prevInvites && prevInvites.filter((invite) => invite.grid_id !== id);
    });
  };

  // Watch for declined invites
  useFetcherWatcher({
    action: resourceRoutes.coLeads,
    formDataAction: 'deleteInvite',
    optimisticUpdate: (fetcherData) => {
      if (!fetcherData?.submission?.formData) return;

      const id = fetcherData?.submission?.formData.get('groupId');
      if (id) removeInvite(Number(id));
    }
  });

  if (invites.length === 0) return null;

  return (
    <ModalConsumer>
      {({ showModal }) =>
      <div className={classNames(className, 'coleadInvitesWrapper')}>
          {invites.map((invite: ColeadInvite) =>
        <ColeadInvites invite={invite} removeInvite={removeInvite} showModal={showModal} key={invite.grid_id} />
        )}
        </div>}

    </ModalConsumer>);

};

export default ColeadInvitesWrapper;