import { ButtonBase, IcFluentChevronRight24Regular } from '@flipgrid/flipkit';

import invitationMethodStyles from '~/styles/components/Utility/components/InvitationMethod.css';

export const links = () => [{ rel: 'stylesheet', href: invitationMethodStyles }];

type Props = {
  className?: string;
  'data-testid': string;
  mediaElement: JSX.Element | string;
  onClick: () => void;
  text: string;
};

const InvitationMethod = ({ className, 'data-testid': dataTestId, mediaElement, onClick, text }: Props) => {
  const isImage = typeof mediaElement === 'string';
  return (
    <ButtonBase
      className={`invitationMethod background__bg-1 ${className ?? ''}`}
      type="button"
      onClick={onClick}
      data-testid={dataTestId}>

      {/* todo: Ian is working on a unique theme for this button, implement when available */}
      <div className="invitationMethod__optionBody">
        {isImage ?
        <img className="invitationMethod__mediaWrapper" src={mediaElement} alt="" /> :

        <div className="invitationMethod__mediaWrapper">{mediaElement}</div>}

        <span className="fk-h4">{text}</span>
      </div>
      <IcFluentChevronRight24Regular className="invitationMethod__inviteChevron" width={20} height={20} />
    </ButtonBase>);

};

export default InvitationMethod;