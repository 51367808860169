import { Button, ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import resourceRoutes from '~/constants/resourceRoutes';
import useGetUser from '~/hooks/useGetUser';

import type { OnRequestCloseType } from '@flipgrid/flipkit';

type Props = {
  groupId: number;
  groupName: string;
  onRequestClose: OnRequestCloseType;
};

const DeclineColeadModal = ({ groupId, groupName, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const user = useGetUser();

  useEffect(() => {
    if (fetcher.type === 'done') {
      onRequestClose(
        t('declineColeadModal.declinedInvitation', {
          title: groupName,
        }),
      );
    }
  }, [fetcher, groupName, onRequestClose, t]);

  const declineInvite = () => {
    const formData = new FormData();
    formData.append('userId', user?.id?.toString() || '');
    formData.append('groupId', groupId.toString());
    formData.append('_action', 'deleteInvite');
    fetcher.submit(formData, { method: 'post', action: resourceRoutes.coLeads });
  };

  return (
    <Modal onClose={onRequestClose}>
      <h1 className="fk-modalHeader">{t('declineColeadModal.declineInvitation')}</h1>
      <p className="fk-m0 fk-modalBody">{t('declineColeadModal.areYouSure')}</p>

      <Modal.Actions className="mt2">
        <Button theme="secondary" data-testid="declineColeadModal__button__cancel" onClick={onRequestClose}>
          {t('common.cancel')}
        </Button>
        <ButtonSubmit
          theme="danger"
          data-testid="declineColeadModal__button__declineModal"
          loading={fetcher.state !== 'idle'}
          onClick={declineInvite}
        >
          {t('common.decline')}
        </ButtonSubmit>
      </Modal.Actions>
    </Modal>
  );
};

export default DeclineColeadModal;
