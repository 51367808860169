import { Button, ButtonSubmit, Checkbox, Modal, Banner } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { has } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import routes from '~/constants/routes';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { Member } from 'types';

type Props = {
  onRequestClose: OnRequestCloseType;
  accessControl?: string;
  removingSelf: boolean;
  groupId: number;
  email?: string;
  membership?: Member | null;
  userName?: string;
  updateLeftRailOnRemoval?: () => void;
};

const RemoveCoLeadModal = ({
  onRequestClose,
  accessControl,
  removingSelf,
  groupId,
  email,
  membership,
  userName,
  updateLeftRailOnRemoval,
}: Props) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [hasError, setHasError] = useState(false);
  const fetcher = useFetcher();

  useEffect(() => {
    if (fetcher.type === 'done') {
      const isError = has(fetcher.data, 'data.error');
      let closeAnnouncement = t('shared.errorOccurred');

      if (!isError) {
        closeAnnouncement = t('removeCoLeadModal.successfullyRemovedCoLead');

        // If you remove yourself as co-lead in non-pln group, remove group from left rail to reflect new "invited" status
        // In pln groups, you are automatically added as member, so no need to remove you from the left rail
        if (removingSelf && accessControl && accessControl !== 'pln') {
          if (updateLeftRailOnRemoval) updateLeftRailOnRemoval();
        }
      } else setHasError(true);
      onRequestClose(closeAnnouncement);
    }
  }, [accessControl, fetcher.data, fetcher.type, onRequestClose, removingSelf, t, updateLeftRailOnRemoval]);

  return (
    <Modal
      onClose={onRequestClose}
      topComponent={
        hasError && (
          <Banner className="mb2" theme="redSecondaryWithBg">
            {t('removeCoLeadModal.problemRemovingCoLead')}
          </Banner>
        )
      }
    >
      <fetcher.Form
        method="post"
        action={removingSelf ? routes.GROUPS_ID_FUNC(groupId) : routes.GROUPS_ID_MEMBERS_FUNC(groupId)}
      >
        <h1 className="fk-modalHeader">{t('shared.removeAsCoLead')}</h1>
        <div className="fk-modalBody">
          <p>
            {removingSelf ? (
              t('removeCoLeadModal.areYouSureYouWantToRemoveSelf')
            ) : (
              <Trans i18nKey="removeCoLeadModal.areYouSureYouWantToRemoveOther">
                Are you sure you want to remove <b>{{ coleadName: userName }}</b> as a co-lead? They will no longer be
                able to co-manage the group.
              </Trans>
            )}
          </p>
          <Checkbox
            checked={checked}
            onChange={() => setChecked(prev => !prev)}
            data-testid="removeCoLeadModal__checkbox__permanentlyRemove"
          >
            {removingSelf ? (
              t('removeCoLeadModal.iWantToPermanentlyRemoveMyself')
            ) : (
              <Trans i18nKey="removeCoLeadModal.iWantToPermanentlyRemoveOther">
                I want to permanently remove <b>{{ coleadName: userName }}</b> as a co-lead.
              </Trans>
            )}
          </Checkbox>
        </div>
        <Modal.Actions className="mt2">
          <Button theme="tertiary" data-testid="removeCoLeadModal__button__cancel" onClick={onRequestClose}>
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            data-testid="removeCoLeadModal__button__remove"
            disabled={!checked}
            name="_action"
            value={removingSelf ? 'removeCoLead' : 'manageCoLead'}
            loading={fetcher.state === 'submitting' || fetcher.state === 'loading'}
          >
            {t('common.remove')}
          </ButtonSubmit>
        </Modal.Actions>
        {removingSelf ? (
          <>
            <input type="hidden" name="gridId" value={membership?.grid_id as number} />
            <input type="hidden" name="userId" value={membership?.user_id as number} />
          </>
        ) : (
          <>
            <input type="hidden" name="grid_id" value={groupId as number} />
            <input type="hidden" name="email" value={email as string} />
            <input type="hidden" name="role" value="member" />
          </>
        )}
      </fetcher.Form>
    </Modal>
  );
};

export default RemoveCoLeadModal;
