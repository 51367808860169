import { Anchor, ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Radio from '~/components/FkWrappers/Radio';
import externalLinks from '~/constants/externalLinks';
import resourceRoutes from '~/constants/resourceRoutes';
import { logEvent } from '~/helper/helper';
import useGetUser from '~/hooks/useGetUser';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { ApiResponse, Group } from 'types';

type Props = {
  group: Group;
  onRequestClose: OnRequestCloseType;
};

const TopicPermissionsModal = ({ group, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const user = useGetUser();

  const [memberTopicCreation, setMemberTopicCreation] = useState(
    group.member_topic_creation ? group.member_topic_creation : false,
  );

  const fetcher = useFetcher<ApiResponse<{ ok?: boolean }>>();

  useEffect(() => {
    if (fetcher.type === 'done') {
      const hasErrors = Object.keys(fetcher.data?.error ?? {}).length > 0;
      if (hasErrors) return;
      onRequestClose(t('topicPermissionsModal.successfullyUpdated'));
    }
  }, [fetcher, onRequestClose, t]);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    formData.append('id', group.id?.toString());
    formData.append('_action', 'updateGroup');
    fetcher.submit(formData, { method: 'post', action: resourceRoutes.updateState });
    logEvent(
      { name: 'member_topic_creation_updated' },
      {
        grid_id: group.id,
        access_control: group?.access_control || undefined,
        user_id: user.id,
        member_id: group?.membership?.id || undefined,
        role: group?.membership?.role || undefined,
        screen: 'TopicView',
        member_topic_creation: memberTopicCreation,
      },
    );
  };

  return (
    <Modal show onClose={onRequestClose}>
      <h1 className="fk-modalHeader">{t('topicPermissionsModal.studentsCanPostTopics')}</h1>
      <p>
        <>
          {t('topicPermissionsModal.allowStudentsInTheGroup')}
          <br />
          <Trans i18nKey="topicPermissionsModal.learnMoreAboutStudentTopicCreation">
            Learn more about student topic creation
            <Anchor
              data-testid="topicPermissionsModal__link__studentTopicCreationHelp"
              to={externalLinks.HelpAllowStudentTopics}
              newTab
            >
              here
            </Anchor>
            .
          </Trans>
        </>
      </p>
      <fetcher.Form className="fk-blockRadio__container" noValidate onSubmit={submit}>
        <Radio
          label={t('topicPermissionsModal.allStudentsCanCreate')}
          name="all_members"
          onChange={() => setMemberTopicCreation(true)}
          selected={memberTopicCreation}
          size="small"
          theme="ghostBlock"
        />
        <Radio
          label={t('topicPermissionsModal.onlyEducatorAndColeads')}
          name="onlyLeads"
          onChange={() => setMemberTopicCreation(false)}
          selected={!memberTopicCreation}
          size="small"
          theme="ghostBlock"
        />
        <Modal.Actions className="mt2">
          <ButtonSubmit data-testid="topicPermissionsModal__button__submit" loading={fetcher.state !== 'idle'}>
            {t('common.done')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="member_topic_creation" value={memberTopicCreation.toString()} />
      </fetcher.Form>
    </Modal>
  );
};

export default TopicPermissionsModal;
