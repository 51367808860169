import {
  Anchor,
  Banner,
  Button,
  IcFluentAddCircle24Filled,
  IcFluentArrowSync24Regular,
  IcFluentDismiss24Regular,
  Input,
  Loader,
  Modal,
  Scrollable,
  Tooltip } from
'@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import RemoveMemberModal from '../MemberActions/RemoveMemberModal';
import AddCoLeadModal, { links as addCoLeadModalStyles } from '~/components/Modals/AddCoLeadModal';
import InfiniteScroll from '~/components/Utility/InfiniteScroll';
import BackButtonHeader, { links as backButtonHeaderStyles } from '~/components/Utility/components/BackButtonHeader';
import UsernameCSVUpload from '~/components/Utility/components/UsernameCsvUpload';
import externalLinks from '~/constants/externalLinks';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';
import addUsernamesModalStyles from '~/styles/components/Modals/GroupTypes/AddUsernamesModal.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { Fetcher } from '@remix-run/react';
import type { MutableRefObject, SetStateAction } from 'react';
import type { Group, Member, Pagination, Username } from 'types';

export const links = () => [
...addCoLeadModalStyles(),
...backButtonHeaderStyles(),
{ rel: 'stylesheet', href: addUsernamesModalStyles }];


type ApiError = {
  error?: {
    model: {
      uid: {
        error: string;
        value: string;
      }[];
    };
  };
};

type Props = {
  group: Group;
  loadMembers: (page: number) => void;
  memberFetcherState: Fetcher['state'];
  memberList: Member[];
  memberPagination: Pagination | undefined;
  onRequestClose: OnRequestCloseType;
  onSuccess: OnRequestCloseType;
  setMemberList: React.Dispatch<SetStateAction<Member[]>>;
};

const AddUsernamesModal = ({
  group,
  loadMembers,
  memberFetcherState,
  memberList,
  memberPagination,
  onRequestClose,
  onSuccess,
  setMemberList
}: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher<{data?: Member[] | ApiError;}>();
  const [usernameClientError, setClientUsernameError] = useState({
    firstName: true,
    lastName: true,
    username: true,
    usernameDuplicate: false
  });
  const [newMembers, setNewMembers] = useState<Username[]>([]);
  const [showAddCoLeadModal, setShowAddCoLeadModal] = useState(false);
  const [duplicateUsernames, setDuplicateUsernames] = useState<string[]>([]);
  const [showApiErrorBanner, setShowApiErrorBanner] = useState(false);
  const [shouldShowClientErrors, setShouldShowClientErrors] = useState(false);
  const [deleteMemberModalData, setDeleteMemberModalData] = useState<{
    show: boolean;
    deletedMember: Member | undefined;
  }>({
    show: false,
    deletedMember: undefined
  });

  const formRef = (useRef() as MutableRefObject<HTMLFormElement>);
  const members = useMemo(() => [...newMembers, ...memberList], [newMembers, memberList]);

  const isSubmitting = fetcher.state !== 'idle';
  const isLoadingMembers = memberFetcherState === 'loading';

  const getFormInputLength = (name: string) => {
    const formInput = new FormData(formRef.current).get(name);
    if (formInput) return (formInput as string).length;
    return 0;
  };

  const getSanitizedUsernameInputs = (formData: FormData) => {
    const removeMultipleSpaceRegex = /  +/g; // hello   there -> hello there
    const firstName = (formData.get('firstName') as string).trim().replace(removeMultipleSpaceRegex, ' ');
    const lastName = (formData.get('lastName') as string).trim().replace(removeMultipleSpaceRegex, ' ');
    const username = (formData.get('username') as string).trim().replace(removeMultipleSpaceRegex, ' ');
    return { first_name: firstName, last_name: lastName, uid: username };
  };

  const updateUsernameValidator = () => {
    const formData = new FormData(formRef.current);
    const { first_name, last_name, uid } = getSanitizedUsernameInputs(formData);
    const isDuplicateUsername = !![...memberList, ...newMembers].find((member) => member.uid === uid);

    setClientUsernameError({
      firstName: first_name.length === 0,
      lastName: last_name.length === 0,
      username: uid.length < 2,
      usernameDuplicate: isDuplicateUsername
    });
  };

  const handleMemberDeletion = (member: Member | Username) => {
    const isCurrentMember = (member as Member).id; // id's only exist for current members
    if (isCurrentMember) {
      setDeleteMemberModalData({ show: true, deletedMember: (member as Member) });
      return;
    }
    setNewMembers((prev) => prev.filter((prevMember) => prevMember.uid !== member.uid));
  };

  const addUsername = () => {
    const hasErrors = Object.values(usernameClientError).find((errorKey) => errorKey);
    if (hasErrors) {
      setShouldShowClientErrors(true);
      return;
    }

    const formData = new FormData(formRef.current);
    const newUsernameMember = getSanitizedUsernameInputs(formData);

    setNewMembers((prevMembers) => [newUsernameMember, ...prevMembers]);
    resetForm();
  };

  const getUsernameRole = (user: Username) => {
    if (!user.role) return t('common.student');
    if (user.role === 'owner') return t('common.educator');
    if (user.role === 'co_owner') return t('common.colead');
    return t('common.student');
  };

  const getUsernameError = () => {
    if (shouldShowClientErrors && (usernameClientError.username || usernameClientError.usernameDuplicate)) {
      return usernameClientError.username ?
      t('addUsernamesModal.usernameTwoCharacters') :
      t('addUsernamesModal.usernameTaken');
    }
    return '';
  };

  const resetForm = () => {
    formRef.current.reset();
    setShouldShowClientErrors(false);
    updateUsernameValidator();
  };

  const handleSubmit = () => {
    const formData = new FormData(formRef.current);
    formData.append('students', JSON.stringify(newMembers));
    fetcher.submit(formData, { method: 'post', action: resourceRoutes.username });
  };

  useEffect(() => {
    if (fetcher.type === 'done') {
      // handle errors
      const errorResponseObj = (fetcher.data as ApiError);
      const hasApiError = Object.keys(errorResponseObj?.error ?? {}).length > 0;

      if (hasApiError) {
        const duplicateUsernameList = errorResponseObj.error?.model ?
        errorResponseObj.error.model.uid.
        filter((errorObj) => errorObj.error === 'taken').
        map((duplicateObj) => duplicateObj.value) :
        [];

        setDuplicateUsernames(duplicateUsernameList);
        setShowApiErrorBanner(true);
        return;
      }

      // handle success
      // clear new members and add them to the members list
      setNewMembers([]);
      setMemberList((prev) => [...(fetcher.data.data as Member[]), ...prev]);
      onSuccess(t('addUsernamesModal.studentsSuccessfullyAdded'));
    }
  }, [fetcher, onSuccess, setMemberList, t]);

  return (
    <Modal onClose={onRequestClose} theme="wide" animation={false}>
      <fetcher.Form action={resourceRoutes.username} method="post" onChange={updateUsernameValidator} ref={formRef}>
        {showApiErrorBanner &&
        <Banner className="mb1" theme="redSecondaryWithBg" onClose={() => setShowApiErrorBanner(false)}>
            <h1 className="mt0 mb1 fk-h4">
              {duplicateUsernames.length === 0 ?
            t('addUsernamesModal.errorAddingStudents') :
            t('addUsernamesModal.usernamesMustBeUnique')}
            </h1>
            <ul>
              {duplicateUsernames.map((username) =>
            <li>{t('addUsernamesModal.specificUsernameTaken', { username })}</li>
            )}
            </ul>
          </Banner>}

        <div className="addUsernamesModal__headerWrapper">
          <BackButtonHeader
            onRequestClose={onRequestClose}
            titleText={t('shared.addUsernames')}
            data-testid="backButtonHeader__button__otherInviteMethods__addUsernamesModal" />

          <Button
            data-testid="addUsernamesModal__button__inviteColead"
            variant="text"
            onClick={() => setShowAddCoLeadModal(true)}>

            {t('shared.inviteColead')}
          </Button>
        </div>

        <div className="addUsernamesModal__addStudentContainer mt2">
          <Input
            inputClassName="addUsernamesModal__input"
            autoComplete="off"
            data-testid="addUsernamesModal__input__firstName"
            onKeyDown={(e: KeyboardEvent) => e.code === 'Enter' ? addUsername() : undefined}
            label={t('common.firstName')}
            error={shouldShowClientErrors && usernameClientError.firstName && t('shared.firstNameIsRequired')}
            name="firstName"
            placeholder={t('common.firstName')}
            wrapperClassName="addUsernamesModal__inputWrapper"
            maxLength={50}
            counter={getFormInputLength('firstName')}
            floatingLabel />

          <Input
            inputClassName="addUsernamesModal__input"
            autoComplete="off"
            name="lastName"
            onKeyDown={(e: KeyboardEvent) => e.code === 'Enter' ? addUsername() : undefined}
            error={shouldShowClientErrors && usernameClientError.lastName && t('shared.lastNameIsRequired')}
            data-testid="addUsernamesModal__input__lastName"
            label={t('common.lastName')}
            placeholder={t('common.lastName')}
            wrapperClassName="addUsernamesModal__inputWrapper"
            maxLength={50}
            counter={getFormInputLength('lastName')}
            floatingLabel />

          <Input
            inputClassName="addUsernamesModal__input"
            autoComplete="off"
            name="username"
            error={getUsernameError()}
            onKeyDown={(e: KeyboardEvent) => e.code === 'Enter' ? addUsername() : undefined}
            data-testid="addUsernamesModal__input__userName"
            label={t('common.username')}
            placeholder={t('common.username')}
            wrapperClassName="addUsernamesModal__inputWrapper"
            maxLength={50}
            counter={getFormInputLength('username')}
            floatingLabel />

          <Tooltip label={t('addUsernamesModal.addStudent')}>
            <Button
              aria-label={t('addUsernamesModal.addStudent')}
              data-testid="addUsernamesModal__button__addStudent"
              onClick={addUsername}
              className="addUsernamesModal__addUsernameBtn m0"
              icon={<IcFluentAddCircle24Filled width={30} height={30} />}
              theme="transparent"
              variant="circle" />

          </Tooltip>
        </div>
        <span>
          <Trans i18nKey="addUsernamesModal.addUsernameOrCsv">
            Add usernames, use our
            <Anchor to={externalLinks.StudentTemplate} data-testid="addUsernamesModal__anchor__helperTemplate" newTab>
              template
            </Anchor>
            or
            <UsernameCSVUpload
              addUsers={(newUsers) => setNewMembers((prev) => [...newUsers, ...prev])}
              groupId={group.id} />

          </Trans>
        </span>

        <table className="addUsernamesModal__table">
          <thead>
            <tr className="addUsernamesModal__titleTableHeader fk-helpText mb1">
              <th className="addUsernamesModal__tableHeader">{t('common.name')}</th>
              <th className="addUsernamesModal__tableHeader">{t('common.username')}</th>
            </tr>
          </thead>
          <tbody className="addUsernamesModal__tableBody">
            {/* scrollable throws a warning, divs cannot be in tbody. Need CSS only mixin for scrollbars */}
            <Scrollable>
              {members.map((member) =>
              <tr className="addUsernamesModal__tableRow" key={member.uid}>
                  <td className="addUsernamesModal__memberDetails ellipsisText mt1 mb1">
                    <div className="fk-h4 ellipsisText">{`${member.first_name} ${member.last_name}`}</div>
                    <div className="addUsernamesModal__memberTitle fk-h4 ellipsisText">{getUsernameRole(member)}</div>
                  </td>
                  <td className="addUsernamesModal__tableUsername ellipsisText fk-h4 mt1 mb1">
                    <div className="ellipsisText">{member.uid}</div>
                  </td>
                  {member.role !== 'owner' &&
                <td className="addUsernamesModal__removeUsername">
                      <Button
                    onClick={() => handleMemberDeletion(member)}
                    icon={<IcFluentDismiss24Regular width={18} height={18} fill="var(--fk-color__foreground-3)" />}
                    data-testid="addUsernamesModal__button__removeUsername"
                    theme="transparent"
                    size="36"
                    aria-label={t('addUsernamesModal.removeStudent')} />

                    </td>}

                </tr>
              )}

              <tr className="addUsernamesModal__tableLoaderRow">
                <td
                  className={`addUsernamesModal__tableLoaderData ${
                  memberPagination?.total === memberList.length ? '--end' : ''
                  }`}>

                  {isLoadingMembers && <Loader />}
                  {memberPagination &&
                  <InfiniteScroll
                    pagination={memberPagination}
                    fetcherState={memberFetcherState}
                    load={() => loadMembers(memberPagination.current_page + 1)} />}


                </td>
              </tr>
            </Scrollable>
          </tbody>
        </table>

        <Anchor
          className="mt075"
          data-testid="addUsernamesModal__anchor__printStudentsList"
          to={routes.GROUPS_ID_GROUPS_NAME_VANITY_TOKEN_BADGES_FUNC(group.id, group.name, group.vanity_token)}>

          {t('shared.printStudentsList')}
        </Anchor>
        <Modal.Actions className="mt2">
          <Button
            onClick={handleSubmit}
            data-testid="addUsernamesModal__button__saveUsernames"
            variant="block"
            icon={
            isSubmitting ? <IcFluentArrowSync24Regular className="addUsernamesModal__submissionIcon" /> : undefined}

            loading={isSubmitting}
            disabled={isSubmitting}>

            {t('common.save')}
          </Button>
        </Modal.Actions>
        <input type="hidden" name="_action" value="add" />
        <input type="hidden" name="groupId" value={group.id} />
      </fetcher.Form>

      {deleteMemberModalData.show && deleteMemberModalData.deletedMember &&
      <RemoveMemberModal
        onRequestClose={() => setDeleteMemberModalData({ show: false, deletedMember: undefined })}
        onMemberRemoval={(deletedMember: Member) =>
        setMemberList((prev) => prev.filter((prevMember) => prevMember.id !== deletedMember.id))}

        groupID={group.id}
        member={deleteMemberModalData.deletedMember} />}



      {showAddCoLeadModal && <AddCoLeadModal group={group} onRequestClose={() => setShowAddCoLeadModal(false)} />}
    </Modal>);

};

export default AddUsernamesModal;