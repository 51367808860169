import { Button, IcFluentArrowSync24Regular } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import resourceRoutes from '~/constants/resourceRoutes';
import GlobalContext from '~/contexts/globalContext';
import { isMemberGroupOwner } from '~/helper/userRoles';

import type { ApiResponse, GoogleSyncError, Group, Job } from 'types';

type SyncError = ApiResponse<void, void, GoogleSyncError>;

type Props = {
  group: Group;
  onSyncError?: (error?: GoogleSyncError) => void;
  onSyncRequest?: () => void;
  onSyncSuccess?: () => void;
  type?: 'default' | 'primary';
};

const SyncGoogleRosterButton = ({ group, onSyncError, onSyncRequest, onSyncSuccess, type = 'default' }: Props) => {
  const { announceLiveMessage } = useContext(GlobalContext);
  const { t } = useTranslation();
  const fetcher = useFetcher<Job | SyncError>();
  const [submissionStatus, setSubmissionStatus] = useState({
    failed: false,
    success: false,
  });
  const isSyncing = fetcher.state !== 'idle';
  const isGroupOwner = isMemberGroupOwner(group);

  const getIconFillClassName = () => {
    if (submissionStatus.success) return '--success';
    if (submissionStatus.failed) return '--error';
    return '';
  };

  const resetSubmissionState = () => {
    setSubmissionStatus(() => ({ failed: false, success: false }));
  };

  const handleSubmit = () => {
    if (onSyncRequest) onSyncRequest();

    resetSubmissionState();
    if (!group.provider_grid_connection) return;

    const formData = new FormData();
    formData.append('_action', 'sync');
    formData.append('connection_id', group.provider_grid_connection.id.toString());

    fetcher.submit(formData, { method: 'post', action: resourceRoutes.googleRoster });
  };

  useEffect(() => {
    const isSubmitted = Object.values(submissionStatus).includes(true);
    if (isSubmitted) return;

    const isJobComplete = fetcher.type === 'done' && (fetcher.data as Job)?.status === 'complete';
    const isJobError =
      fetcher.type === 'done' &&
      (!fetcher.data || !isEmpty((fetcher.data as SyncError)?.error) || (fetcher.data as Job)?.status === 'failed');

    if (isJobComplete) {
      setSubmissionStatus(prev => ({ ...prev, success: true }));
      announceLiveMessage(t('googleRoster.successSync'));
      if (onSyncSuccess) onSyncSuccess();
    } else if (isJobError) {
      setSubmissionStatus(prev => ({ ...prev, failed: true }));
      announceLiveMessage(t('googleRoster.problemSync'));
      if (onSyncError) onSyncError((fetcher.data as SyncError)?.error);
    }
  }, [announceLiveMessage, fetcher.data, fetcher.type, onSyncError, onSyncSuccess, submissionStatus, t]);

  // if there is no connection, there is nothing to sync- so do not render
  // likewise if they are not an admin, do not render
  if (!group.provider_grid_connection || !isGroupOwner) return null;

  if (type === 'primary') {
    return (
      <Button
        theme="secondary"
        onClick={handleSubmit}
        icon={
          isSyncing ? <IcFluentArrowSync24Regular className="googleRosterSessionStatus__syncBtn--syncing" /> : undefined
        }
        size="36"
        data-testid="syncGoogleRosterButton__button__syncPrimary"
        disabled={isSyncing}
      >
        {t('googleRoster.syncRoster')}
      </Button>
    );
  }

  return (
    <Button
      className={classNames(
        'googleRosterSessionStatus__syncBtn',
        { color__success: submissionStatus.success },
        { color__error: submissionStatus.failed },
      )}
      onClick={handleSubmit}
      variant="text"
      icon={
        <IcFluentArrowSync24Regular
          className={`googleRosterSessionStatus__syncBtn${isSyncing ? '--syncing' : ''} ${getIconFillClassName()}`}
        />
      }
      data-testid="syncGoogleRosterButton__button__sync"
    >
      {t('googleRoster.sync')}
    </Button>
  );
};

export default SyncGoogleRosterButton;
