import { Button } from '@flipgrid/flipkit';
import { useFetcher, useMatches } from '@remix-run/react';
import { parse } from 'cookie';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import GoogleIcon from '../../../../svg/GoogleIcon';
import SyncGoogleRosterButton from '~/components/Utility/Buttons/SyncGoogleRosterButton';
import resourceRoutes from '~/constants/resourceRoutes';
import googleRosterSessionStatusStyles from '~/styles/components/Modals/GoogleClassroom/GoogleRosterSessionStatus.css';

import type { Dispatch, SetStateAction } from 'react';
import type { ApiResponse, DeleteResponseType, GoogleSyncError, GoogleToken, Group } from 'types';

export const links = () => [{ rel: 'stylesheet', href: googleRosterSessionStatusStyles }];

type Props = {
  className?: string;
  googleToken: GoogleToken | undefined;
  onLogout?: () => void;
  onSyncError?: (error?: GoogleSyncError) => void;
  onSyncRequest?: () => void;
  onSyncSuccess?: () => void;
  setGoogleToken: Dispatch<SetStateAction<GoogleToken | undefined>>;
  showUI: boolean;
} & (
{
  group?: never;
  type: 'Settings';
} |
{
  group: Group;
  type: 'Group';
});


type ErrorResponse = {
  data: {
    error: boolean;
  };
  message: string;
  status: number;
};

const GoogleRosterSessionStatus = ({
  className = '',
  googleToken,
  group,
  onLogout,
  onSyncSuccess,
  onSyncRequest,
  onSyncError,
  setGoogleToken,
  showUI = true,
  type
}: Props) => {
  const { t } = useTranslation();
  const deleteTokenFetcher = useFetcher<DeleteResponseType>();
  const generateTokenFetcher = useFetcher<ApiResponse<GoogleToken> | ErrorResponse>();
  const [hasInitializedToken, setHasInitializedToken] = useState(false);
  const [routeData] = useMatches();
  const popup = useRef<Window | null>(null);

  const hasActiveSession = !!googleToken;
  const hasFetchedToken = generateTokenFetcher.type === 'done';

  const loadGoogleSession = useCallback(() => {
    const formData = new FormData();
    formData.append('_action', 'generateToken');
    generateTokenFetcher.submit(formData, { method: 'post', action: resourceRoutes.googleRoster });
  }, [generateTokenFetcher]);

  // on component load, check for an existing active Google session
  useEffect(() => {
    if (!hasInitializedToken && generateTokenFetcher.state === 'idle') {
      setHasInitializedToken(true);
      loadGoogleSession();
    }
  }, [generateTokenFetcher, hasInitializedToken, loadGoogleSession]);

  // set the google token response when we have one
  useEffect(() => {
    const hasSession = generateTokenFetcher.data && (generateTokenFetcher.data?.data as GoogleToken).email;
    if (hasSession) {
      if (!generateTokenFetcher.data || !generateTokenFetcher.data.data) return;
      setGoogleToken({ ...(generateTokenFetcher.data.data as GoogleToken) });
    }
  }, [generateTokenFetcher, setGoogleToken]);

  // handle token deletions
  useEffect(() => {
    const hasDeletedSession = deleteTokenFetcher.data?.ok;
    if (hasDeletedSession) {
      setGoogleToken(undefined);
      deleteTokenFetcher.data = undefined; // reset deletion status
      if (onLogout) onLogout();
    }
  }, [deleteTokenFetcher, onLogout, setGoogleToken]);

  const deleteGoogleActiveSession = () => {
    const formData = new FormData();
    formData.append('_action', 'deleteToken');
    deleteTokenFetcher.submit(formData, { method: 'post', action: resourceRoutes.googleRoster });
  };

  const startPostMessageListener = () => {
    const interval = setInterval(() => {
      if (popup.current) {
        popup.current.postMessage('fg-admin-login-status-request', '*');
      }
    }, 1000);

    window.addEventListener('message', (event) => {
      if (event.data === 'fg-admin-login-successful') {
        if (popup.current) popup.current.close();
        clearInterval(interval);
        loadGoogleSession();
      } else if (event.data === 'fg-admin-login-error') {
        if (popup.current) popup.current.close();
        clearInterval(interval);
        loadGoogleSession();
      }
    });
  };

  const googleSSOUrl = (state: {[key: string]: TSFix;}) => {
    return (
      `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?` +
      'response_type=code&' +
      'response_mode=query&' +
      'include_granted_scopes=true&' +
      'access_type=offline&' +
      'approval_prompt=force&' +
      `client_id=${state.client_id}&` +
      `redirect_uri=${state.redirect_uri}&` +
      `scope=${state.scopes}&` +
      `state=${btoa(JSON.stringify(state))}`);

  };

  const googleOpenPopup = (url: string, title: string, w: number, h: number) => {
    // Credit - https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
    const { innerWidth, innerHeight, screenTop, screenLeft } = window;
    const { clientWidth, clientHeight } = document.documentElement;

    const width = innerWidth || clientWidth;
    const height = innerHeight || clientHeight;

    const left = width / 2 - w / 2 + screenLeft;
    const top = height / 2 - h / 2 + screenTop;
    const newWindow = window.open(
      url,
      title,
      'scrollbars=yes, rel=noopener, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left
    );

    // Puts focus on the newWindow
    if (newWindow && newWindow.focus) {
      newWindow.focus();
    }

    return newWindow;
  };

  // CLIENT_ADMIN_URL is used for local development when we run the
  // server as a separate instance from the dev server
  const openPopup = () => {
    const state = {
      scopes: [
      'https://www.googleapis.com/auth/classroom.profile.emails',
      'https://www.googleapis.com/auth/classroom.rosters.readonly',
      'https://www.googleapis.com/auth/classroom.courses.readonly',
      'email'].
      join(' '),
      client_id: routeData.data.env.GOOGLE_CLIENT_ID,
      redirect_uri: `${routeData.data.env.ADMIN_URL}/auth/google-classroom/sso`,
      access_token: parse(document.cookie).ACCESS_TOKEN
    };
    const url = googleSSOUrl(state);
    popup.current = googleOpenPopup(url, 'Sign in through Google', 400, 500);
    startPostMessageListener();
  };

  if (!showUI || typeof window === 'undefined' || !document) return null;

  return (
    <>
      {hasActiveSession ?
      type === 'Group' ?
      <div className={`googleRosterSessionStatus__signInWrapper mt2 mb1 ${className}`}>
            <p className="fk-fontWeight__bold m0">{googleToken?.email}</p>
            <Button
          data-testid="googleRosterSessionStatus__button__logout"
          variant="text"
          onClick={deleteGoogleActiveSession}>

              {t('common.unlink')}
            </Button>
            <SyncGoogleRosterButton
          group={group}
          onSyncError={onSyncError}
          onSyncRequest={onSyncRequest}
          onSyncSuccess={onSyncSuccess} />

          </div> :

      <p className="googleRosterSessionStatus__user">
            <Trans i18nKey="googleRoster.youAreSignedIn">
              You are signed in as <strong>{{ email: googleToken?.email }}</strong>.
              <Button
            data-testid="googleRosterSessionStatus__button__logout"
            variant="text"
            onClick={deleteGoogleActiveSession}
            theme="muted">

                Sign out
              </Button>
              .
            </Trans>
          </p> :


      hasInitializedToken &&
      hasFetchedToken &&
      <Button
        className={type === 'Group' ? 'mt2' : ''}
        data-testid="googleRosterSessionStatus__button__google"
        icon={<GoogleIcon size="20px" />}
        onClick={openPopup}
        theme="secondary">

            {t('shared.continueWithGoogle')}
          </Button>}


    </>);

};

export default GoogleRosterSessionStatus;