import { Dropdown, IcFluentSave24Regular } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';

import resourceRoutes from '~/constants/resourceRoutes';

import type { FetcherWithComponents } from '@remix-run/react';
import type { Job } from 'types';

type Props = {
  fetcher: FetcherWithComponents<string | Job>;
  groupId: number;
} & (
  | {
      openModal?: never;
      topicId?: never;
      type: 'topics';
      videoCount?: never;
    }
  | {
      openModal: (exportingData: (async: boolean) => void) => void;
      topicId: number;
      type: 'responses';
      videoCount: number;
    }
);

const ExportDataDropdown = ({ fetcher, groupId, openModal, topicId, type, videoCount }: Props) => {
  const { t } = useTranslation();

  const exportingData = (async: boolean) => {
    const formData = new FormData();
    if (type === 'responses') {
      formData.append('id', topicId.toString());
      formData.append('async', async ? 'true' : 'false');
    }
    formData.append('grid_id', groupId.toString());
    formData.append('type', type);
    formData.append('_action', 'export_data');
    fetcher.submit(formData, { method: 'post', action: resourceRoutes.exportData });
  };

  const downloadData = () => {
    if (type === 'responses' && videoCount > 100) openModal(exportingData);
    else exportingData(false);
  };

  return (
    <Dropdown.Item
      iconLeft={<IcFluentSave24Regular />}
      onSelect={downloadData}
      data-testid="exportDataDropdown__dropdownItem__exportData"
    >
      {t('shared.exportData')}
    </Dropdown.Item>
  );
};

export default ExportDataDropdown;
