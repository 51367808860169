import { Button } from '@flipgrid/flipkit';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { validateUser, USER_ERRORS, parseUserCSV } from '~/helper/usernameCsvUploadHelpers';

import type { ChangeEvent } from 'react';
import type { Member, Username } from 'types';

type Props = {
  addUsers: (valid: TSFix, errors: {}, groupId: number) => void;
  buttonText?: string;
  className?: string;
  existingUsers?: Username[];
  groupId: number;
};

const UsernameCSVUpload = ({ addUsers, buttonText = '', className = '', existingUsers = [], groupId }: Props) => {
  const { t } = useTranslation();
  const csvFile = useRef<HTMLInputElement>(null);

  const validateUploadedUsers = (uploaded: Username[]) => {
    // This error format is used be the error formatting helper function to
    // show the user helpful error messages.
    let uploadedUserErrorIndexMap: TSFix = {
      missingFirst: [],
      missingLast: [],
      invalidUsername: [],
      invalidColumns: [],
      duplicateUsername: [],
    };
    const valid: Username[] = [];
    const validateAgainst = [...existingUsers];
    // if a value is undefined that column does not exist, so we can check for undefines for the first element.
    if (uploaded[0].first_name === undefined) uploadedUserErrorIndexMap.invalidColumns.push('first_name');
    if (uploaded[0].last_name === undefined) uploadedUserErrorIndexMap.invalidColumns.push('last_name');
    if (uploaded[0].uid === undefined) uploadedUserErrorIndexMap.invalidColumns.push('id');

    uploaded.forEach((user, index) => {
      const errors = validateUser(user, validateAgainst as TSFix);
      if (Object.keys(errors).length > 0) {
        if (errors.first_name) uploadedUserErrorIndexMap?.missingFirst.push(`${index + 2}`);
        if (errors.last_name) uploadedUserErrorIndexMap?.missingLast.push(`${index + 2}`);
        if (errors.uid === USER_ERRORS().USERNAME_TOO_SHORT)
          uploadedUserErrorIndexMap?.invalidUsername.push(`${index + 2}`);
        if (errors.uid === USER_ERRORS().USERNAME_OVERLAP)
          uploadedUserErrorIndexMap?.duplicateUsername.push(`${index + 2}`);
      } else {
        valid.push(user);
        validateAgainst.push(user);
      }
    });

    if (!Object.values(uploadedUserErrorIndexMap).find((i: TSFix) => i.length)) uploadedUserErrorIndexMap = null;

    return [valid, uploadedUserErrorIndexMap];
  };

  const readCSVFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.target && e.target.files ? e.target.files[0] : undefined;

    if (!file) return;

    parseUserCSV(file)
      .then((users: TSFix) => {
        const uploaded = users.map((user: Partial<Member>) => {
          return { first_name: user.first_name, last_name: user.last_name, uid: user.id };
        });
        const [valid, errors] = validateUploadedUsers(uploaded);
        addUsers(valid, errors, groupId);
      })
      .catch(() => {
        addUsers([], { invalidCSV: true }, groupId);
      });
  };

  const fileUpload = () => {
    // In order to have a anchor as a file input, must click it via JS.
    if (csvFile.current?.click) csvFile.current.click();
  };

  return (
    <>
      <Button
        className={className}
        data-testid="usernameCSVUpload__button__uploadCSV"
        onClick={fileUpload}
        variant="text"
      >
        {buttonText || t('shared.uploadCSV')}
      </Button>
      <input
        type="file"
        accept=".csv"
        ref={csvFile}
        className="fk-hidden"
        onChange={e => {
          // this fixes an issue where if the user uploads the same file twice,
          // the input doesn't recognize the change and the onchange doesn't fire a second time
          e.persist();
          readCSVFile(e);
          e.target.value = '';
        }}
      />
    </>
  );
};

export default UsernameCSVUpload;
