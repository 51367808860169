import {
  Dropdown,
  IcFluentDelete24Regular,
  IcFluentEdit24Regular,
  IcFluentFlag24Regular,
  IcFluentMail24Regular,
  IcFluentMegaphone24Regular,
  IcFluentMoreHorizontal24Regular,
  IcFluentQrCode24Regular,
  IcFluentRestore24Regular,
  IcFluentShareIos24Regular,
  IcFluentSignOut24Regular,
  ModalConsumer,
} from '@flipgrid/flipkit';
import { useFetcher, useLocation, useMatches } from '@remix-run/react';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ColeadBtn, { links as coleadBtnStyles } from '../Coleads/ColeadBtn';
import ActiveStateDropdown from '../Dropdown/ActiveStateDropdown';
import ExportDataDropdown from '../Dropdown/ExportDataDropdown';
import DropdownLink from '../FkWrappers/DropdownLink';
import DuplicateGroupModal from '../Modals/Duplicate/DuplicateGroupModal';
import DeleteGroupModal from '../Modals/GroupDetails/DeleteGroupModal';
import DisconnectCanvasModal from '../Modals/GroupDetails/DisconnectCanvasModal';
import FollowGroupModal from '../Modals/GroupDetails/FollowGroupModal';
import LeaveGroupModal from '../Modals/GroupDetails/LeaveGroupModal';
import TopicPermissionsModal from '../Modals/GroupDetails/TopicPermissionsModal';
import ShareGroupModalBtn, { links as shareGroupModalBtnStyles } from '../Share/ShareGroupModalBtn';
import ReportModal, { links as reportModalStyles } from '~/components/Report/ReportModal';
import HandleIds from '~/constants/handleIds';
import routes from '~/constants/routes';
import globalContext from '~/contexts/globalContext';
import topicListContext from '~/contexts/topicListContext';
import checkIsLtiGroup from '~/helper/checkIsLtiGroup';
import { downloadCsv } from '~/helper/component/downloadCsv';
import { isFlagPresent } from '~/helper/helper';
import { isMemberGroupLead, isMemberGroupOwner, userBelongsToGroup } from '~/helper/userRoles';
import useGetUser from '~/hooks/useGetUser';
import { useGroupsStateMachine } from '~/statemachine/GroupsStateMachineContext';

import type { KeyboardEvent, MouseEvent } from 'react';
import type { DisplayGroup, Group, Job, RouteTyping } from 'types';

export const links = () => [...coleadBtnStyles(), ...reportModalStyles(), ...shareGroupModalBtnStyles()];

type Props =
  | {
      group: Group;
      variant: 'table';
    }
  | {
      group: Group | DisplayGroup;
      variant: 'header';
    };

const GroupActionsDropdown = ({ group, variant }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const matches = useMatches();
  const ua = (matches.find(m => m.handle?.id === HandleIds.Root)?.data as RouteTyping<'Root'>)?.ua;
  const user = useGetUser();
  const exportDataFetcher = useFetcher<Job | string>();
  const isLoggedOutGroup = 'resource_type' in group;
  const { studentView } = useContext(topicListContext);
  const isGroupLead = !isLoggedOutGroup && isMemberGroupLead(group, studentView);
  const isGroupOwner = !isLoggedOutGroup && isMemberGroupOwner(group, studentView);
  const isUsernameGroup = group.access_control === 'student';
  const isLtiGroup = checkIsLtiGroup(group);
  const isPassportGroup = user && user.identities?.includes('passport');
  const location = useLocation();
  const groupStateMachine = useGroupsStateMachine();
  const { send } = groupStateMachine.groupsService;

  const functionalGroup = isLoggedOutGroup && group.resource_type !== 'grid' ? group.grid : group;
  const blocked = functionalGroup.membership?.blocked;

  const { featureFlags } = useContext(globalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  const allColeads =
    location.pathname === routes.GROUPS
      ? []
      : (matches.find(m => m.handle?.id === HandleIds.Group)?.data as RouteTyping<'Group'>)?.coleads?.data;

  useEffect(() => {
    if (exportDataFetcher.type === 'done' && exportDataFetcher.data) {
      downloadCsv(exportDataFetcher.data, 'topics.csv', 'text/csv', ua?.safari);
    }
  }, [exportDataFetcher, ua?.safari]);

  return (
    <ModalConsumer>
      {({ showModal }) => (
        <Dropdown
          button={
            <Dropdown.Button
              aria-controls=""
              aria-label={t('groupActionsDropdown.dropdownButton')}
              data-testid="groupActionsDropdown__button__dropdown"
              icon={<IcFluentMoreHorizontal24Regular />}
              onClick={(e: MouseEvent | KeyboardEvent) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              theme={variant === 'header' ? 'overlay' : 'tertiary'}
              size={variant === 'header' ? undefined : '36'}
            />
          }
        >
          {isGroupLead && (
            <>
              {isDeprecated ? null : (
                <DropdownLink
                  data-testid="groupActionsDropdown__dropdownItem__editGroup"
                  icon={<IcFluentEdit24Regular />}
                  to={routes.GROUPS_ID_UPDATE_FUNC(group.id)}
                  className={location.pathname === routes.GROUPS ? '-groupsTableEditDropdown' : ''} // Class only for the groupsTable
                >
                  {t('shared.editGroup')}
                </DropdownLink>
              )}
              {isDeprecated
                ? null
                : !isUsernameGroup &&
                  !isLtiGroup &&
                  !isPassportGroup && (
                    <Dropdown.Item
                      data-testid="groupActionsDropdown__dropdownItem__topicPermissions"
                      icon={<IcFluentMegaphone24Regular />}
                      onSelect={() => showModal(TopicPermissionsModal, { group })}
                    >
                      {t('groupActionsDropdown.topicPermissions')}
                    </Dropdown.Item>
                  )}
              {isDeprecated ? null : (
                <Dropdown.Item
                  data-testid="groupActionsDropdown__dropdownItem__duplicateGroup"
                  icon={<IcFluentRestore24Regular />}
                  onSelect={() => showModal(DuplicateGroupModal, { group })}
                >
                  {t('groupActionsDropdown.duplicateGroup')}
                </Dropdown.Item>
              )}
              {!isDeprecated && <ActiveStateDropdown updateStateFetcher={fetcher} item={group} type="Group" />}
              {isDeprecated ? null : <Dropdown.Divider />}
            </>
          )}

          {isDeprecated
            ? null
            : location.pathname !== routes.GROUPS &&
              isGroupOwner && (
                <ColeadBtn
                  group={group}
                  coleads={allColeads}
                  hasColeads={!!(allColeads && allColeads.length > 0)}
                  showModal={showModal}
                />
              )}

          {/* There is no scenario where the group is logged out when on the groups path */}
          {location.pathname === routes.GROUPS && !isLoggedOutGroup && (
            <ShareGroupModalBtn
              className="-groupsTableShareDropdown"
              group={group}
              icon={<IcFluentShareIos24Regular />}
              showModal={showModal}
              type="dropdownItem"
            />
          )}

          {isDeprecated
            ? null
            : !isUsernameGroup &&
              !blocked &&
              !isLoggedOutGroup && (
                <Dropdown.Item
                  data-testid="groupActionsDropdown__dropdownItem__emailNotifications"
                  icon={<IcFluentMail24Regular />}
                  onSelect={() => showModal(FollowGroupModal, { isGroupLead, group })}
                >
                  {t('common.emailNotifications')}
                </Dropdown.Item>
              )}

          {isGroupLead && (
            <>
              {isUsernameGroup && (
                <DropdownLink
                  icon={<IcFluentQrCode24Regular />}
                  data-testid="groupActionsDropdown__dropdownLink__printStudentsList"
                  to={routes.GROUPS_ID_GROUPS_NAME_VANITY_TOKEN_BADGES_FUNC(group.id, group.name, group.vanity_token)}
                >
                  {t('shared.printStudentsList')}
                </DropdownLink>
              )}
              <ExportDataDropdown fetcher={exportDataFetcher} groupId={group.id} type="topics" />
              <Dropdown.Divider />
            </>
          )}

          {isGroupOwner && (
            <>
              {isDeprecated ? null : (
                <Dropdown.Item
                  data-testid="groupActionsDropdown__dropdownItem__deleteGroup"
                  icon={<IcFluentDelete24Regular />}
                  onSelect={() =>
                    showModal(DeleteGroupModal, {
                      group,
                      onGroupDeletion: () => send('REMOVE', { group }),
                    })
                  }
                >
                  {t('shared.deleteGroup')}
                </Dropdown.Item>
              )}
              {isLtiGroup && (
                <Dropdown.Item
                  data-testid="groupActionsDropdown__dropdownItem__disconnectFromCanvas"
                  icon={<IcFluentSignOut24Regular />}
                  onSelect={() =>
                    showModal(DisconnectCanvasModal, {
                      group,
                    })
                  }
                >
                  {t('groupActionsDropdown.disconnectFromCanvas')}
                </Dropdown.Item>
              )}
            </>
          )}

          {!isLoggedOutGroup && !isGroupLead && userBelongsToGroup(group) && (
            <Dropdown.Item
              onSelect={() =>
                showModal(LeaveGroupModal, {
                  groupInfo: group,
                  onLeaveGroup: () => send('REMOVE', { group }),
                })
              }
              icon={<IcFluentSignOut24Regular />}
              data-testid="groupActionsDropdown__dropdownItem__leaveGroup"
            >
              {t('shared.leaveGroup')}
            </Dropdown.Item>
          )}
          {/* WIP - https://dev.azure.com/Flipgrid/FG/_workitems/edit/59557} */}
          {/* There are stages to deprecation, the report functionality remains until July 31st, 2024  */}
          {/* <Dropdown.Item
            data-testid="groupActionsDropdown__dropdownItem__reportGroup"
            icon={<IcFluentFlag24Regular />}
            onSelect={() =>
              showModal(ReportModal, {
                contentName: functionalGroup.name,
                contentType: 'group',
                gridId: functionalGroup.id,
              })
            }
          >
            {t('shared.reportGroup')}
          </Dropdown.Item> */}
        </Dropdown>
      )}
    </ModalConsumer>
  );
};

export default GroupActionsDropdown;
