import { ButtonSubmit, Modal, Input } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Radio from '~/components/FkWrappers/Radio';
import resourceRoutes from '~/constants/resourceRoutes';
import useGetUser from '~/hooks/useGetUser';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { ApiResponse, Group } from 'types';

type Props = {
  group: Group;
  isGroupLead: boolean;
  onRequestClose: OnRequestCloseType;
};

const FollowGroupModal = ({ group, isGroupLead, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const [hasEmailError, setHasEmailError] = useState(false);
  const user = useGetUser();
  const isUsernameGroup = group.access_control === 'student';

  const defaultFrequency = () => {
    if ((!group.digest_frequency || group.digest_frequency === 'off') && group.email_owner === true) return 'every';
    if (group.digest_frequency === 'weekly') return 'weekly';
    if (group.digest_frequency === 'daily') return 'daily';

    return 'none';
  };

  const [frequency, setFrequency] = useState(isGroupLead ? defaultFrequency() : 'weekly');
  const fetcher = useFetcher<ApiResponse<{ ok?: boolean }>>();

  useEffect(() => {
    if (fetcher.type === 'done') {
      const hasErrors = Object.keys(fetcher.data?.error ?? {}).length > 0;
      if (hasErrors) {
        setHasEmailError(fetcher.data?.error.status === 422);
        return;
      }
      onRequestClose(t('followGroupModal.successfullyUpdated'));
    }
  }, [fetcher, onRequestClose, t]);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    formData.append('id', group.id?.toString());

    if (isGroupLead) {
      const digestFrequency = formData.get('frequency') as string;
      formData.append(
        'digest_frequency',
        digestFrequency === 'none' || digestFrequency === 'every'
          ? JSON.stringify(null)
          : JSON.stringify(digestFrequency),
      );
      formData.append('email_owner', digestFrequency === 'every' ? 'true' : 'false');
      formData.append('fieldsToParse', JSON.stringify(['email_owner', 'digest_frequency']));
      formData.append('_action', 'updateGroup');
      fetcher.submit(formData, { method: 'post', action: `${resourceRoutes.updateState}?manage=true` });
    } else {
      // Append additional formData
      if (user?.email) formData.append('email', user.email);

      // Submit the formData
      fetcher.submit(formData, { method: 'post', action: resourceRoutes.subscribe });
    }
  };

  return (
    <Modal show onClose={onRequestClose}>
      <h1 className="fk-modalHeader">{t('common.emailNotifications')}</h1>
      <p>{isGroupLead ? t('followGroupModal.updateFrequency') : t('followGroupModal.stayUpToDate')}</p>

      <fetcher.Form className="fk-blockRadio__container" noValidate onSubmit={submit}>
        {isUsernameGroup && !user?.email && (
          <Input
            autoCapitalize="none"
            error={hasEmailError ? t('followGroupModal.invalidEmail') : ''}
            label={t('followGroupModal.preferredEmailAddress')}
            maxLength={100}
            name="email"
            onChange={() => setHasEmailError(false)}
            placeholder={t('followGroupModal.yourEmailAddress')}
            type="email"
            required
          />
        )}

        <h2 className="fk-externalLabel fk-mt0">{t('common.emailFrequency')}</h2>
        {isGroupLead && (
          <>
            <Radio
              label={t('common.none')}
              name="frequency"
              onChange={() => setFrequency('none')}
              selected={frequency === 'none'}
              size="small"
              theme="ghostBlock"
              value="none"
            />
            <Radio
              label={t('followGroupModal.everyNewVideoAndComment')}
              name="frequency"
              onChange={() => setFrequency('every')}
              selected={frequency === 'every'}
              size="small"
              theme="ghostBlock"
              value="every"
            />
          </>
        )}
        <Radio
          label={t('common.daily')}
          name="frequency"
          onChange={() => setFrequency('daily')}
          selected={frequency === 'daily'}
          size="small"
          theme="ghostBlock"
          value="daily"
        />
        <Radio
          label={t('common.weekly')}
          name="frequency"
          onChange={() => setFrequency('weekly')}
          selected={frequency === 'weekly'}
          size="small"
          theme="ghostBlock"
          value="weekly"
        />
        <Modal.Actions className="mt2">
          <ButtonSubmit
            data-testid="followGroupModal__button__submit"
            disabled={!frequency}
            loading={fetcher.state !== 'idle'}
          >
            {t('common.done')}
          </ButtonSubmit>
        </Modal.Actions>
      </fetcher.Form>
    </Modal>
  );
};

export default FollowGroupModal;
