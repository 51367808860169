import { Button, ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { has } from 'lodash-es';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { formatName } from '~/helper/formatting';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { Member } from 'types';

type Props = {
  groupID: number;
  member: Member;
  onRequestClose: OnRequestCloseType;
  onMemberRemoval?: (member: Member) => void;
};

const RemoveMemberModal = ({ groupID, member, onRequestClose, onMemberRemoval }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const memberDisplayName = formatName(member) ?? member.email;

  useEffect(() => {
    if (fetcher.type === 'done') {
      const isError = has(fetcher, 'data.data.error');
      let closeAnnouncement = t('shared.errorOccurred');

      if (!isError) {
        closeAnnouncement = t('removeMemberModal.successfullyRemovedStudent');
        if (onMemberRemoval) {
          onMemberRemoval(member);
        }
      }

      onRequestClose(closeAnnouncement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher, member, onRequestClose, groupID, t]);

  return (
    <Modal show onClose={onRequestClose}>
      <fetcher.Form method="post" action={`groups/${groupID}/members`}>
        <h1 className="fk-modalHeader">{t('removeMemberModal.removeStudent', { student: memberDisplayName })}</h1>
        <p className="fk-modalBody">
          {t('removeMemberModal.permanentStudentRemovalExplanation', { student: memberDisplayName })}
        </p>
        <Modal.Actions className="mt2">
          <Button
            theme="secondary"
            data-testid="deleteMemberConfirmationModal__button__cancel"
            onClick={onRequestClose}
          >
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            name="_action"
            value="removeMember"
            theme="danger"
            data-testid="removeMemberModal__button__removeAndDelete"
            loading={fetcher.state === 'submitting' || fetcher.state === 'loading'}
          >
            {t('removeMemberModal.removeAndDelete')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="removedMemberID" value={member.id} />
        <input type="hidden" name="destroy_responses" value="true" />
        <input type="hidden" name="gridID" value={groupID} />
      </fetcher.Form>
    </Modal>
  );
};
export default RemoveMemberModal;
