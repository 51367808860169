import { Anchor, Modal } from '@flipgrid/flipkit';
import { useMatches } from '@remix-run/react';
import { useTranslation } from 'react-i18next';

import externalLinks from '~/constants/externalLinks';
import { signInUrl } from '~/helper/urlHelper';
import maxAccountsModalStyles from '~/styles/components/Modals/MaxAccountsModal.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { RouteType } from 'types';

type Props = {
  onRequestClose: OnRequestCloseType;
  redirectRoute: RouteType;
};

export const links = () => [{ rel: 'stylesheet', href: maxAccountsModalStyles }];

const MaxAccountModal = ({ onRequestClose, redirectRoute }: Props) => {
  const { t } = useTranslation();
  const [routeData] = useMatches();
  const { env } = routeData.data;

  return (
    <Modal className="maxAccountModal" closeButtonTheme="overlay" onClose={onRequestClose} show>
      <img alt="" className="maxAccountModal__image" src={externalLinks.EligibleFeaturesImage} />
      <div className="maxAccountModal__text">
        <h1 className="fk-modalHeader">{t('maxAccountModal.eligibilityCheckNeeded')}</h1>
        <p className="fk-modalBody">{t('maxAccountModal.moreInformationNeeded')}</p>
        <Modal.Actions className="mt2">
          <Anchor
            data-testid="maxAccountModal__button"
            to={signInUrl(env.CLIENT_AUTH_SERVICE_URL, `${env.ADMIN_URL}/manage${redirectRoute}`)} // need admin & /manage text in URL for backend to refresh token on age verification
            variant="button">

            {t('common.continue')}
          </Anchor>
        </Modal.Actions>
      </div>
    </Modal>);

};

export default MaxAccountModal;