import { Banner, Button, ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import resourceRoutes from '~/constants/resourceRoutes';

import type { OnRequestCloseType } from '@flipgrid/flipkit';

type Props = {
  groupId: number;
  onContinue: () => void;
  onRequestClose: OnRequestCloseType;
};

const UsernameRestrictionModal = ({ groupId, onContinue, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const [hasError, setHasError] = useState(false);

  const isLoading = fetcher.state !== 'idle';

  useEffect(() => {
    const hasUpdatedGroupType = fetcher.type === 'done';

    if (hasUpdatedGroupType) {
      const isInvalidResponse = !isEmpty(fetcher.data?.error);
      if (isInvalidResponse) {
        setHasError(true);
        return;
      }

      onContinue();
    }
  }, [fetcher, onContinue]);

  return (
    <Modal
      onClose={onRequestClose}
      animation={false}
      topComponent={
        hasError && (
          <Banner className="mb2" theme="redSecondaryWithBg">
            {t('usernameRestrictionModal.problemConvertingGroup')}
          </Banner>
        )
      }
    >
      <fetcher.Form method="post" action={resourceRoutes.updateState}>
        <h1 className="fk-modalHeader">{t('usernameRestrictionModal.restrictGroupUsername')}</h1>
        <p className="fk-modalBody">{t('usernameRestrictionModal.createUsernameDescription')}</p>
        <Modal.Actions className="mt2">
          <Button theme="secondary" onClick={onRequestClose} data-testid="usernameRestrictionModal__button__cancel">
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            name="_action"
            value="updateGroup"
            data-testid="usernameRestrictionModal__button__continue"
            theme="danger"
            loading={isLoading}
          >
            {t('common.continue')}
          </ButtonSubmit>
        </Modal.Actions>

        <input type="hidden" name="id" value={groupId} />
        <input type="hidden" name="access_control" value="student" />
        <input type="hidden" name="member_topic_creation" value="false" />
        <input type="hidden" name="fieldsToParse" value={JSON.stringify(['member_topic_creation'])} />
      </fetcher.Form>
    </Modal>
  );
};

export default UsernameRestrictionModal;
