import { Anchor, Button, ButtonSubmit, DefaultProfilePic, Modal, ModalConsumer } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import RemoveMemberModal from './MemberActions/RemoveMemberModal';
import Input from '../FkWrappers/Input';
import RemoveCoLeadModal from '~/components/Modals/RemoveCoLeadModal';
import externalLinks from '~/constants/externalLinks';
import routes from '~/constants/routes';
import GlobalContext from '~/contexts/globalContext';
import { getFirstLastNameOrFallback } from '~/helper/formatting';
import addCoLeadModalStyles from '~/styles/components/Modals/AddCoLeadModal.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { ApiResponse, Group, Member } from 'types';

export const links = () => [{ rel: 'stylesheet', href: addCoLeadModalStyles }];

type Props = {
  group: Group;
  coleads?: Member[];
  onRequestClose: OnRequestCloseType;
};

type ErrorResponse = {
  messages: string[];
  model?: {base: {error?: string;value?: string;}[];user_id?: {error?: string;value?: string;}[];};
  status: number;
};

const AddCoLeadModal = ({ group, coleads, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const { announceLiveMessage } = useContext(GlobalContext);

  const fetcher = useFetcher<ApiResponse<Member, Member, ErrorResponse>>();
  const submissionComplete = fetcher.type === 'done';

  useEffect(() => {
    const isSubmissionSuccess = submissionComplete && fetcher.data?.data?.id;
    if (isSubmissionSuccess) {
      announceLiveMessage(t('addCoLeadModal.yourCoLeadAddedSuccessfully'));
      onRequestClose();
    }
  }, [announceLiveMessage, fetcher, onRequestClose, submissionComplete, t]);

  const getDisplayRole = (role: string) => {
    switch (role) {
      case 'pending':
        return t('addCoLeadModal.pendingInvite');
      default:
        return t('addCoLeadModal.coLead');
    }
  };

  const getResponseError = () => {
    const hasErrors = Object.keys(fetcher.data?.error ?? {}).length > 0;
    if (submissionComplete && hasErrors) {
      const hasErrorMessage = fetcher.data.error.messages && fetcher.data.error.messages.length > 0;
      if (hasErrorMessage) return fetcher.data.error.messages[0];
      return t('addCoLeadModal.problemAddingCoLead');
    }
    return '';
  };

  return (
    <Modal onClose={onRequestClose} show>
      <fetcher.Form method="post" action={routes.GROUPS_ID_MEMBERS_FUNC(group.id)}>
        <h1 className="fk-modalHeader mb1">{t('addCoLeadModal.addCoLead')}</h1>
        <p className="fk-m0 fk-modalBody mb2">
          <Trans i18nKey="addCoLeadModal.coLeadsGroupDescription">
            Co-leads can edit the group, create topics and approve responses and comments.
            <Anchor to={externalLinks.CoLeads} data-testid="addCoLeadModal__anchor__help" newTab>
              Learn more about co-leads.
            </Anchor>
          </Trans>
        </p>

        <Input
          error={getResponseError()}
          label={t('shared.inviteColead')}
          maxLength={100}
          name="co_owner"
          placeholder={t('addCoLeadModal.enterEmail')}
          rightComponent={
          <ButtonSubmit
            className="ml1"
            data-testid="addCoLeadModal__button__invite"
            size="52"
            name="_action"
            value="addCoLead"
            loading={fetcher.state !== 'idle'}>

              {t('common.invite')}
            </ButtonSubmit>}

          type="email" />

        <input type="hidden" name="group_id" value={group.id} />
      </fetcher.Form>
      {coleads && coleads?.length > 0 &&
      <>
          <h4 className="addCoLeadModal__coleadsListHeader">{t('addCoLeadModal.coLeads')}</h4>
          <ul className="addCoLeadModal__coleadsList">
            {coleads?.map((colead: Member, index: number) => {
            return (
              <li key={`${index}-${colead.id}`}>
                  {colead.image_url ? <img src={colead.image_url} alt="" /> : <DefaultProfilePic id={colead.id} />}
                  <p>
                    <b>{getFirstLastNameOrFallback(colead)}</b>
                    <br />
                    <span>{getDisplayRole(colead.role)}</span>
                  </p>
                  <ModalConsumer>
                    {({ showModal }) =>
                  <Button
                    aria-label={t('addCoLeadModal.removeColead')}
                    className="addCoLeadModal__removeButton"
                    data-testid="addCoLeadModal__button__removeColead"
                    theme="danger"
                    variant="text"
                    onClick={
                    colead.role === 'pending' ?
                    () => showModal(RemoveMemberModal, { groupID: group.id, member: colead }) :
                    () =>
                    showModal(RemoveCoLeadModal, {
                      removingSelf: false,
                      groupId: group.id,
                      email: colead.email,
                      userName: colead.first_name
                    })}>


                        {t('common.remove')}
                      </Button>}

                  </ModalConsumer>
                </li>);

          })}
          </ul>
        </>}

    </Modal>);

};

export default AddCoLeadModal;