import { Dropdown, IcFluentPersonAdd24Regular } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';

import AddCoLeadModal, { links as addCoLeadModalStyles } from '~/components/Modals/AddCoLeadModal';

import type { ShowModalType } from '@flipgrid/flipkit';
import type { Group, Member } from 'types';

export const links = () => [...addCoLeadModalStyles()];

type Props = {
  group: Group;
  coleads?: Member[];
  hasColeads: boolean;
  showModal: ShowModalType;
};

const ColeadBtn = ({ group, coleads, hasColeads, showModal }: Props) => {
  const { t } = useTranslation();

  return (
    <Dropdown.Item
      data-testid="coleadBtn__button__manageColeads"
      onSelect={() => {
        if (hasColeads) {
          showModal(AddCoLeadModal, {
            group,
            coleads,
          });
        } else {
          showModal(AddCoLeadModal, {
            group,
          });
        }
      }}
      icon={<IcFluentPersonAdd24Regular />}
    >
      {hasColeads ? t('coleadBtn.manageColeads') : t('coleadBtn.addColead')}
    </Dropdown.Item>
  );
};

export default ColeadBtn;
