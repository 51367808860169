import { Button, ButtonSubmit, Checkbox, Modal } from '@flipgrid/flipkit';
import { useFetcher, useLocation } from '@remix-run/react';
import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { Group } from 'types';

type Props = {
  group: Group;
  onGroupDeletion: () => void;
  onRequestClose: OnRequestCloseType;
};

const DeleteGroupModal = ({ group, onGroupDeletion, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const groupId = group.id;
  const fetcher = useFetcher();
  const location = useLocation();

  // TODO Task 48205: Add error handling for Modals
  useEffect(() => {
    if (fetcher.type === 'done') {
      onGroupDeletion();
      onRequestClose(t('deleteGroupModal.groupWasDeletedModalClosed', { groupName: group.name }));
    }
  }, [group.name, onRequestClose, t, fetcher, onGroupDeletion]);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Append additional formData
    const formData = new FormData(e.currentTarget);
    if (location.pathname !== routes.GROUPS) formData.append('onSuccessRedirect', '/');

    // Submit the formData
    fetcher.submit(formData, { method: 'delete', action: resourceRoutes.deleteGroup });
  };

  return (
    <Modal onClose={onRequestClose}>
      <fetcher.Form onSubmit={submit}>
        <h1 className="fk-modalHeader">{t('shared.deleteGroup')}</h1>
        <p className="fk-modalBody">
          <Trans
            i18nKey="deleteGroupModal.confirmDeleteGroup"
            values={{
              topics: group.topic_count || 0,
              videos: group.response_count || 0,
            }}
          >
            By deleting {{ groupName: group.name }} you will also permanently delete
            {{ topicResponseCount: group.topic_count }}
            topic and {{ GroupResponseCount: group.response_count }} video. This action cannot be undone.
          </Trans>
        </p>
        <Checkbox
          wrapperClassName="mt1"
          data-testid="deleteGroupModal__checkbox__confirmDelete"
          checked={checked}
          onChange={() => setChecked(prev => !prev)}
        >
          {t('deleteGroupModal.wantToDeleteGroup')}
        </Checkbox>

        <Modal.Actions className="mt2">
          <Button theme="secondary" data-testid="deleteGroupModal__button__cancel" onClick={onRequestClose}>
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            theme="danger"
            data-testid="deleteGroupModal__button__removeOrDelete"
            loading={fetcher.state !== 'idle'}
            disabled={!checked}
          >
            {t('common.delete')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="groupId" value={groupId} />
      </fetcher.Form>
    </Modal>
  );
};

export default DeleteGroupModal;
