import { Button, Dropdown, IcFluentShareIos24Regular, Tooltip } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ShareGroupModalWrapper, { links as shareGroupModalWrapperStyles } from '../Modals/ShareGroupModalWrapper';
import resourceRoutes from '~/constants/resourceRoutes';
import checkIsLtiGroup from '~/helper/checkIsLtiGroup';

import type { ShowModalType, actionSizes, themesForAllVariants, themesForNonTextVariants } from '@flipgrid/flipkit';
import type { LinksFunction } from '@remix-run/node';
import type { FormEvent } from 'react';
import type { DisplayGroup, Group } from 'types';

export const links: LinksFunction = () => [...shareGroupModalWrapperStyles()];

type Props = {
  btnSize?: actionSizes;
  btnTheme?: themesForAllVariants | themesForNonTextVariants;
  className?: string;
  disabled?: boolean;
  loadShareModalOnInit?: boolean;
  group: Group | DisplayGroup;
  icon?: JSX.Element;
  showModal: ShowModalType;
  showText?: boolean;
} & (
  | {
      children?: never;
      type?: 'button' | 'dropdownItem';
    }
  | {
      children: string;
      type: 'text';
    }
);

const ShareGroupModalBtn = ({
  btnSize = '44',
  btnTheme = 'overlay',
  children,
  className,
  disabled,
  loadShareModalOnInit = false,
  group,
  icon = <IcFluentShareIos24Regular />,
  showModal,
  showText,
  type = 'button',
}: Props) => {
  const { t } = useTranslation();
  const emailDomainUpdateFetcher = useFetcher();
  const updateAccessControlFetcher = useFetcher();
  const isLtiGroup = checkIsLtiGroup(group);

  useEffect(() => {
    if (loadShareModalOnInit) {
      showModal(ShareGroupModalWrapper, { group, updateAccessControl, updateDomainsAndEmails });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  const updateDomainsAndEmails = (formData: FormData) => {
    emailDomainUpdateFetcher.submit(formData, {
      method: 'post',
      action: resourceRoutes.emailSignup,
    });
  };

  const updateAccessControl = (e: FormEvent<HTMLFormElement>) => {
    const params = new URLSearchParams({ manage: 'true' });
    updateAccessControlFetcher.submit(e.currentTarget, {
      method: 'post',
      action: `${resourceRoutes.updateState}?${params.toString()}`,
    });
  };

  if (type === 'text')
    return (
      <Button
        className={className}
        theme="primary"
        size={btnSize}
        variant="text"
        data-testid="shareGroupModalBtn__button__shareText"
        onClick={() => showModal(ShareGroupModalWrapper, { group, updateAccessControl, updateDomainsAndEmails })}
        disabled={isLtiGroup || disabled}
      >
        {children}
      </Button>
    );

  if (type === 'dropdownItem')
    return (
      <Dropdown.Item
        data-testid="shareGroupModalBtn__dropdownItem__shareGroup"
        icon={icon}
        onSelect={() => showModal(ShareGroupModalWrapper, { group, updateAccessControl, updateDomainsAndEmails })}
        className={className}
        disabled={isLtiGroup || disabled}
      >
        {t('shared.shareGroup')}
      </Dropdown.Item>
    );

  return (
    <Tooltip label={t('common.share')}>
      <Button
        aria-label={t('common.share')}
        className={className}
        theme={btnTheme}
        icon={icon}
        size={btnSize}
        data-testid="shareGroupModalBtn__button__share"
        onClick={() => showModal(ShareGroupModalWrapper, { group, updateAccessControl, updateDomainsAndEmails })}
        disabled={isLtiGroup || disabled}
      >
        {showText && t('common.share')}
      </Button>
    </Tooltip>
  );
};

export default ShareGroupModalBtn;
