import {
  Banner,
  Button,
  ButtonSubmit,
  IcFluentAddCircle24Filled,
  IcFluentDismiss24Regular,
  Input,
  Loader,
  Modal,
  Scrollable } from
'@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import DomainConfirmationModal from './DomainConfirmationModal';
import DomainDeleteModal from '../DomainDeleteModal';
import BackButtonHeader, { links as backButtonHeaderStyles } from '~/components/Utility/components/BackButtonHeader';
import CSVErrorBanner, { links as csvErrorBannerStyles } from '~/components/Utility/components/CSVErrorBanner';
import EmailCSVUpload from '~/components/Utility/components/EmailCsvUpload';
import resourceRoutes from '~/constants/resourceRoutes';
import shareGroupModalContext from '~/contexts/shareGroupModalContext';
import { validateEmail } from '~/helper/domainCsvHelper';
import addEmailsModalStyles from '~/styles/components/Modals/GroupTypes/AddEmailsModal.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { MutableRefObject } from 'react';
import type { DisplayGroup, Group } from 'types';

export const links = () => [
...backButtonHeaderStyles(),
...csvErrorBannerStyles(),
{ rel: 'stylesheet', href: addEmailsModalStyles }];


type DomainsAndEmailsResponse = {
  isSuccess: boolean;
  status: {addEmailSuccess: boolean;addDomainSuccess: boolean;addEmailInProgress: boolean;};
};

type Props = {
  group: Group | DisplayGroup;
  onRequestClose: OnRequestCloseType;
  onCompletion: OnRequestCloseType;
};

const AddEmailsModal = ({ group, onCompletion, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const ref = (useRef() as MutableRefObject<HTMLFormElement>);
  const emailFetcher = useFetcher<DomainsAndEmailsResponse>();
  const validationFetcher = useFetcher<{emailOrDomain: string;isValid: boolean;messageKey?: string;}>();
  const csvFetcher = useFetcher<{validEmailsOrDomains: string[];invalidEmailsOrDomains: string[];}>();

  const [newEmailsOrDomains, setNewEmailsOrDomains] = useState<string[]>([]);
  const [domains, setDomains] = useState(group.email_domains);
  const [csvErrors, setCsvErrors] = useState<{mainError: string;errorDetails?: string;}>();
  const [domainDeleteModalState, setDomainDeleteModalState] = useState({ show: false, domain: '' });
  const [isEmailPending, setIsEmailPending] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const { setAccessControl } = useContext(shareGroupModalContext);
  const [showDomainConfirmationPrompt, setShowDomainConfirmationPrompt] = useState(false);

  const displayEmails = useMemo(() => [...newEmailsOrDomains, ...(domains ?? [])], [newEmailsOrDomains, domains]);
  const hasInputError = validationFetcher.type === 'done' && !validationFetcher.data?.isValid;

  const confirmBeforeClose = useCallback(() => {
    if (newEmailsOrDomains.length > 0) setShowDomainConfirmationPrompt(true);else
    onRequestClose();
  }, [newEmailsOrDomains.length, onRequestClose]);

  const addCsvEmails = useCallback(
    (emailsAndDomains: {email: boolean;value: string;}[] | undefined) => {
      if (!emailsAndDomains) return;

      const extractedEmailsAndDomains = emailsAndDomains.map((emailDomain) => emailDomain.value);

      const formData = new FormData();
      formData.append('_action', 'validateCSV');
      formData.append('group_id', group.id.toString());
      formData.append('emailsOrDomains', JSON.stringify(extractedEmailsAndDomains));
      formData.append('pendingEmails', JSON.stringify(displayEmails));
      csvFetcher.submit(formData, { method: 'post', action: resourceRoutes.emailSignup });
    },
    [csvFetcher, displayEmails, group.id]
  );

  const handleRemoveMemberOrDomain = useCallback(
    (emailOrDomain: string, isEmail: boolean) => {
      const isPendingDomain = !domains?.includes(emailOrDomain);
      if (isEmail || isPendingDomain) {
        const removedMemberList = newEmailsOrDomains.filter((email) => email !== emailOrDomain);
        setNewEmailsOrDomains(removedMemberList);
        return;
      }
      setDomainDeleteModalState({ show: true, domain: emailOrDomain });
    },
    [domains, newEmailsOrDomains]
  );

  const submitDomainsAndEmails = useCallback(() => {
    setIsEmailPending(false);
    setSubmissionError(false);
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('_action', 'addEmailAndDomains');
    formData.append('access_control', 'domain');
    formData.append('group_id', group.id.toString());
    formData.append('pendingEmailsAndDomains', JSON.stringify(newEmailsOrDomains));
    formData.append('currentDomains', JSON.stringify(domains));
    emailFetcher.submit(formData, {
      method: 'post',
      action: resourceRoutes.emailSignup
    });
    setAccessControl('domain');
  }, [domains, emailFetcher, group.id, newEmailsOrDomains, setAccessControl]);

  const loadModalBanner = useCallback(() => {
    if (isEmailPending) {
      return (
        <Banner theme="yellowWithBg" className="mb2">
          {t('addEmailsModal.emailInProgress')}
        </Banner>);

    }
    if (submissionError) {
      return (
        <Banner theme="redSecondaryWithBg" className="mb2">
          {t('addEmailsModal.problemUpdatingData')}
        </Banner>);

    }
  }, [isEmailPending, submissionError, t]);

  useEffect(() => {
    if (validationFetcher.type === 'done' && validationFetcher.data && validationFetcher.data.isValid) {
      const newEmailOrDomain = validationFetcher.data.emailOrDomain;
      setNewEmailsOrDomains((prev) => [newEmailOrDomain, ...prev]);

      ref.current?.reset();
    }
  }, [validationFetcher.data, validationFetcher.type]);

  useEffect(() => {
    if (csvFetcher.type === 'done') {
      setNewEmailsOrDomains((prev) => [...csvFetcher.data.validEmailsOrDomains, ...prev]);
    }
  }, [csvFetcher]);

  useEffect(() => {
    const response = emailFetcher.data;
    // When the fetcher is done.
    if (emailFetcher.type === 'done') {
      if (response) {
        if (response.isSuccess) {
          onCompletion(t('addEmailsModal.successfullyAddedData'));
          return;
        }

        if (response.status.addEmailInProgress) {
          setIsEmailPending(true);
          // clear out pending emails
          setNewEmailsOrDomains([]);
        } else if (!response.isSuccess) {
          setSubmissionError(true);
        }

        setIsSubmitting(false);
      } else {
        // Else there is no response and an error.
        setSubmissionError(true);
        setIsSubmitting(false);
      }
    }
  }, [emailFetcher.data, emailFetcher.type, onCompletion, t]);

  return (
    <Modal onClose={confirmBeforeClose} topComponent={loadModalBanner()} animation={false}>
      <BackButtonHeader
        onRequestClose={confirmBeforeClose}
        titleText={t('common.addEmailOrDomain')}
        data-testid="backButtonHeader__button__otherInviteMethods__addEmailOrDomain" />


      <div className="fk-modalBody mt2">
        <validationFetcher.Form action={resourceRoutes.emailSignup} method="post" ref={ref}>
          <Input
            data-testid="addEmailsModal__button__typeEmailOrDomain"
            error={
            hasInputError && validationFetcher.data?.messageKey ?
            t((validationFetcher.data.messageKey as TSFix)) :
            undefined}

            label={t('addEmailsModal.addEmail')}
            name="emailOrDomain"
            placeholder={t('addEmailsModal.typeAddressOrDomain')}
            inputButton={
            <Button
              aria-label={t('addEmailsModal.addEmail')}
              data-testid="addEmailsModal__button__addEmail"
              icon={<IcFluentAddCircle24Filled />}
              size="26"
              variant="circle"
              theme="transparent"
              type="submit"
              disabled={validationFetcher.state !== 'idle'} />}


            maxLength={50}
            floatingLabel />

          <input type="hidden" name="_action" value="validate" />
          <input type="hidden" name="group_id" value={group.id} />
          <input type="hidden" name="pendingEmails" value={JSON.stringify(displayEmails)} />
        </validationFetcher.Form>
        <p className="addEmailsModal__uploadCsv mb2">
          <Trans i18nKey="addEmailsModal.tipUploadEmailCsv">
            Tip: you can approve an entire email domain to join by typing e.g @edu.scholastica.com or you can
            <EmailCSVUpload
              onChange={(csvData) => {
                // clear out any existing errors
                setCsvErrors(undefined);
                // if there are errors, load in valid emails/domains and display an error
                if (csvData.csvErrors.mainErrors) {
                  addCsvEmails(csvData.csvErrors.emailsAndDomains);
                  setCsvErrors({
                    errorDetails: csvData.csvErrors.errorDetails,
                    mainError: csvData.csvErrors.mainErrors
                  });
                  // otherwise load in all valid emails/domains
                } else addCsvEmails(csvData.emailsAndDomains);
              }} />

          </Trans>
        </p>

        {csvFetcher.state !== 'idle' ?
        <Loader container /> :

        csvErrors?.mainError &&
        <CSVErrorBanner errorDetails={csvErrors?.errorDetails} mainError={csvErrors?.mainError} />}



        <Scrollable className="addEmailsModal__tableScrollWrapper">
          <div
            className="addEmailsModal__emailTable"
            role="table"
            aria-rowcount={displayEmails.length}
            aria-label={t('addEmailsModal.addedEmailTable')}>

            <div role="rowgroup">
              {displayEmails.map((emailOrDomain, i) => {
                const isEmail = validateEmail(emailOrDomain);
                return (
                  <div className="addEmailsModal__row" role="row" aria-rowindex={i} key={emailOrDomain}>
                    <span className="addEmailsModal__emailDomainWrapper" role="cell">
                      <span className="addEmailsModal__email">{emailOrDomain}</span>
                      {!isEmail &&
                      <span className="addEmailsModal__domainLabel" role="cell">
                          {t('addEmailsModal.domain')}
                        </span>}

                    </span>
                    <span className="addEmailsModal__cellActions">
                      {isEmail &&
                      <span className="addEmailsModal__memberRole" role="cell">
                          {t('common.student')}
                        </span>}

                      <Button
                        className="addEmailsModal__removeMemberBtn"
                        onClick={() => handleRemoveMemberOrDomain(emailOrDomain, isEmail)}
                        data-testid="addEmailsModal__button__removeEmail"
                        role="cell"
                        size="36"
                        theme="transparent">

                        <IcFluentDismiss24Regular fill="var(--fk-color__foreground-3)" width={18} height={18} />
                      </Button>
                    </span>
                  </div>);

              })}
            </div>
          </div>
        </Scrollable>
      </div>
      <Modal.Actions className="mt2">
        <ButtonSubmit
          data-testid="addEmailsModal__buttonSubmit__invite"
          variant="block"
          loading={isSubmitting}
          onClick={submitDomainsAndEmails}>

          {t('common.confirm')}
        </ButtonSubmit>
      </Modal.Actions>
      {domains && domainDeleteModalState.show &&
      <DomainDeleteModal
        existingDomains={domains}
        groupId={group.id}
        onRequestClose={() => setDomainDeleteModalState({ show: false, domain: '' })}
        onSuccess={(updatedDomains) => setDomains(updatedDomains)}
        removedDomain={domainDeleteModalState.domain} />}


      {showDomainConfirmationPrompt &&
      <DomainConfirmationModal
        onRequestClose={onRequestClose}
        onContinue={() => {
          setShowDomainConfirmationPrompt(false);
        }} />}


    </Modal>);

};

export default AddEmailsModal;