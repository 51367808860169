import { Banner, Button, ButtonSubmit } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { has } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import DeclineColeadModal from './DeclineColeadModal';
import MaxAccountModal, { links as maxAccountModalStyles } from '../Modals/MaxAccountsModal';
import ProfilePicture, { links as profilePictureStyles } from '../Utility/components/ProfilePicture';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';
import GlobalContext from '~/contexts/globalContext';
import { getFirstLastNameOrFallback } from '~/helper/formatting';
import { logEvent } from '~/helper/helper';
import { isUserEducator } from '~/helper/userRoles';
import useGetUser from '~/hooks/useGetUser';
import coleadInvitesStyles from '~/styles/components/Coleads/ColeadInvites.css';

import type { ShowModalType } from '@flipgrid/flipkit';
import type { LinksFunction } from '@remix-run/node';
import type { ColeadInvite } from 'types';

export const links: LinksFunction = () => {
  return [...maxAccountModalStyles(), ...profilePictureStyles(), { rel: 'stylesheet', href: coleadInvitesStyles }];
};

type Props = {
  invite: ColeadInvite;
  removeInvite: (id: number) => void;
  showModal: ShowModalType;
};

const ColeadInvites = ({ invite, removeInvite, showModal }: Props) => {
  const { t } = useTranslation();
  const { announceLiveMessage } = useContext(GlobalContext);
  const fetcher = useFetcher();
  const user = useGetUser();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (fetcher.type === 'done' && !hasError) {
      const isSuccess = has(fetcher, 'data.data.grid_id');
      if (isSuccess) {
        removeInvite(fetcher.data.data.grid_id);
        return;
      }

      setHasError(true);
      logEvent(
        { name: 'AcceptInvite::Error' },
        {
          error: JSON.stringify(fetcher.data)
        }
      );
    }
  }, [fetcher, hasError, removeInvite]);

  const acceptInvite = async (groupId: number) => {
    const formData = new FormData();
    formData.append('groupId', groupId.toString());
    formData.append('_action', 'acceptInvite');
    fetcher.submit(formData, { method: 'post', action: resourceRoutes.coLeads });
  };

  const onAcceptInvite = () => {
    const userIsEducator = isUserEducator(user);
    if (!userIsEducator) {
      showModal(MaxAccountModal, { redirectRoute: routes.GROUPS });
      return;
    }

    acceptInvite(invite.grid_id);
    announceLiveMessage(t('coleadInvites.acceptedInvitation', { groupName: invite.grid_name }));
  };

  if (hasError) {
    return (
      <Banner className="coleadInvites__banner fadeInDown" key={invite.grid_id} theme="redWithBg">
        {t('coleadInvites.problemAcceptingInvite', { title: invite.grid_name })}
      </Banner>);

  }

  return (
    <Banner className="coleadInvites__banner fadeInDown" key={invite.grid_id}>
      <ProfilePicture
        className="coleadInvites__image"
        image_url={invite.grid_owner.user.image_url}
        id={invite.grid_owner.user?.id} />

      <div>
        <Trans i18nKey="coleadInvites.youHaveBeenInvited">
          <b>{{ name: getFirstLastNameOrFallback(invite.grid_owner.user) }}</b>
          &nbsp;has invited you to be a Co-lead in <b>{{ title: invite.grid_name }}</b>
        </Trans>
      </div>

      <div className="coleadInvites__invitationButtons">
        <Button
          theme="secondary"
          data-testid="coleadInvites__button__decline"
          onClick={() => {
            showModal(DeclineColeadModal, {
              groupId: invite.grid_id,
              groupName: invite.grid_name
            });
          }}
          size="36">

          {t('common.decline')}
        </Button>
        <ButtonSubmit
          className="ml1"
          data-testid="coleadInvites__button__accept"
          loading={fetcher.state !== 'idle'}
          onClick={onAcceptInvite}
          size="36">

          {t('common.accept')}
        </ButtonSubmit>
      </div>
    </Banner>);

};

export default ColeadInvites;