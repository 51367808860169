import { Button, ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { Group } from 'types';
import type { DuplicateReturnType } from '~/routes/resource-routes/duplicate-group';

type Props = {
  group: Group;
  onRequestClose: OnRequestCloseType;
};

const DuplicateGroupModal = ({ group, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher<DuplicateReturnType>();
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const isSubmitting = fetcher.state !== 'idle';

  const submissionPending = fetcher.type === 'done' && fetcher?.data?.result?.status === 'working';
  const submissionError = fetcher.type === 'done' && fetcher?.data?.result?.status === 'failed';

  useEffect(() => {
    if (fetcher.type === 'actionRedirect' || (fetcher.type === 'done' && fetcher?.data?.result?.status === 'complete'))
      setSubmissionSuccess(true);
  }, [fetcher.type, fetcher?.data?.result.status]);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Append additional formData
    const formData = new FormData(e.currentTarget);
    if (location.pathname !== routes.GROUPS) formData.append('onSuccessRedirect', 'true');

    // Submit the formData
    fetcher.submit(formData, { method: 'post', action: resourceRoutes.duplicateGroup });
  };

  if (submissionPending)
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('shared.stillWorking')}</h1>
        <p className="fk-modalBody">{t('duplicateGroupModal.duplicateStillInProgress')}</p>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="duplicateGroupModal__button__close">
            {t('common.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

  if (submissionError)
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('common.oops')}</h1>
        <p className="fk-modalBody">{t('duplicateGroupModal.duplicateError')}</p>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="duplicateGroupModal__button__close">
            {t('common.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

  if (submissionSuccess)
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('shared.duplicateComplete')}</h1>
        <p className="fk-modalBody">{t('duplicateGroupModal.groupDuplicationComplete')}</p>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="duplicateGroupModal__button__close">
            {t('common.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

  return (
    <Modal onClose={onRequestClose}>
      <fetcher.Form method="post" onSubmit={submit}>
        <h1 className="fk-modalHeader">{t('duplicateGroupModal.duplicateThisGroup')}</h1>
        <p className="fk-modalBody">
          <Trans i18nKey="duplicateGroupModal.onlyTopics" groupName={group.name}>
            Only topics in the group <strong>{{ groupName: group.name }}</strong> will be included. Responses and
            comments will not be duplicated.
          </Trans>
        </p>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="duplicateGroupModal__button__cancel">
            {t('common.cancel')}
          </Button>
          <ButtonSubmit data-testid="duplicateGroupModal__button__duplicate" loading={isSubmitting}>
            {t('shared.duplicate')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="groupId" value={group.id} />
      </fetcher.Form>
    </Modal>
  );
};

export default DuplicateGroupModal;
