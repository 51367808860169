import { Button, Modal } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';

import type { OnRequestCloseType } from '@flipgrid/flipkit';

type Props = {
  onContinue: () => void;
  onRequestClose: OnRequestCloseType;
};

const DomainConfirmationModal = ({ onContinue, onRequestClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onRequestClose} animation={false}>
      <h1 className="fk-modalHeader">{t('domainConfirmationModal.discardChanges')}</h1>
      <p className="fk-modalBody">{t('domainConfirmationModal.ifYouLeaveNow')}</p>
      <Modal.Actions className="mt2">
        <Button theme="secondary" onClick={onRequestClose} data-testid="domainConfirmationModal__button__cancel">
          {t('domainConfirmationModal.discardChanges')}
        </Button>
        <Button theme="primary" onClick={onContinue} data-testid="domainConfirmationModal__button__continue">
          {t('domainConfirmationModal.goBack')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DomainConfirmationModal;
