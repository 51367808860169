import { Button, IcFluentChevronLeft24Regular } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';

import backButtonHeadertyles from '~/styles/components/Utility/components/BackButtonHeader.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';

export const links = () => [{ rel: 'stylesheet', href: backButtonHeadertyles }];

type Props = {
  'data-testid': string;
  onRequestClose: OnRequestCloseType;
  titleText: string;
};

const BackButtonHeader = ({ 'data-testid': dataTestId, onRequestClose, titleText }: Props) => {
  const { t } = useTranslation();
  return (
    <h1 className="fk-modalHeader backButtonHeader__headerWrapper">
      <>
        <Button
          iconLeft={<IcFluentChevronLeft24Regular className="backButtonHeader__backBtn" width={18} height={18} />}
          data-testid={dataTestId}
          variant="text"
          onClick={onRequestClose}
          aria-label={t('common.back')} />

        {titleText}
      </>
    </h1>);

};

export default BackButtonHeader;